import {
    Box,
    Divider,
    FormControlLabel,
    Typography,
    Button,
    TextField,
} from "@material-ui/core";
import { useState, useEffect } from "react";
import AppCheckBox from "../../../shared/components/AppCheckBox";
import AppCustomSwitchBox from "../../../shared/components/AppCustomSwitch";
import { AccessManagementService } from "../../../services";
import { Utils } from "../../../shared/helpers";
import { toast } from "react-toastify";

const AccessManageEditModal = (props) => {
    const {
        setShowEditModel,
        memberDetails,
        loadPolicyMembersHistory,
        showSaveButton,
    } = props;
    const { policyDetails } = memberDetails;
    const [activateAll, setActivateAll] = useState(false);
    const [activateAllAutoTemp, setActivateAllAutoTemp] = useState(false);
    const [activateAllPreDefinedTemp, setactivateAllPreDefinedTemp] =
        useState(false);

    const [loader, setLoader] = useState(false);
    const [InitialLoad, setInitialLoad] = useState(true);

    const [autoRedactionChecked, setAutoRedactionChecked] = useState(
        policyDetails.autoRedactionEnabled
    );

    const [customRedactionChecked, setCustomRedactionChecked] = useState(
        policyDetails.customRedactionEnabled
    );
    const [isPercentageRedactionEnabled, setIsPercentageRedactionEnabled] =
        useState(true);

    const [isDrawRedactionEnabled, setIsDrawRedactionEnabled] = useState(
        policyDetails.drawRedactionEnabled
    );
    const [isTemplateRedactionEnabled, setIsTemplateRedactionEnabled] =
        useState(policyDetails.templateRedactionEnabled);
    const [groupName, setGroupName] = useState(memberDetails.name);

    const [templates, setTemplates] = useState(policyDetails.templates);
    const [autoRedactiontemplates, setAutoRedactionTemplates] = useState(
        policyDetails.autoRedactionTemplates
    );
    const [preSelectedTemplates, setPreSelectedTemplates] = useState(
        policyDetails.preselectedTemplates
    );

    const handlePolicyChange = (event, policyChange) => {
        policyChange(event.target.checked);
    };
    const handleChangeTemplate = (event, index) => {
        templates[index].enabled = event.target.checked;
        setTemplates([...templates]);
        preSelectedList(templates);
    };
    const handleChangeAutoRedactionTemplate = (event, index) => {
        autoRedactiontemplates[index].enabled = event.target.checked;
        setAutoRedactionTemplates([...autoRedactiontemplates]);
    };
    const handleChangePreDefinedTemp = (event, index) => {
        preSelectedTemplates[index].enabled = event.target.checked;
        setPreSelectedTemplates([...preSelectedTemplates]);
    };

    const onChangeActivateAll = (event) => {
        templates.forEach((t, i) => {
            templates[i].enabled = event;
            setTemplates([...templates]);
        });
    };
    const onChangeActivateAllAutoRedaction = (event) => {
        autoRedactiontemplates.forEach((t, i) => {
            autoRedactiontemplates[i].enabled = event;
            setAutoRedactionTemplates([...autoRedactiontemplates]);
        });
    };

    const onChangeActivateAllPreDefinedTemp = (event) => {
        preSelectedTemplates.forEach((t, i) => {
            if (preSelectedTemplates[i].disable) {
                preSelectedTemplates[i].enabled = event;
            }
            setPreSelectedTemplates([...preSelectedTemplates]);
        });
    };

    // PreSelected List
    const preSelectedList = (templates) => {
        const showPrefinedTempArray = [];
        templates.forEach((tmp) => {
            showPrefinedTempArray.push({
                displayName: tmp.displayName,
                enabled: tmp.enabled,
                _id: tmp._id,
                key: tmp.key,
                order: tmp.order,
                disable: tmp.enabled,
                value: tmp.value,
            });
        });
        setPreSelectedTemplates([...showPrefinedTempArray]);
    };

    const initialPreSelectList = () => {
        const newSelectedTemplates = [];
        preSelectedTemplates.forEach((tmp) => {
            const isPredefinedIndex = templates.findIndex(
                (p) => p.enabled && tmp.key === p.key
            );
            if (isPredefinedIndex > -1) {
                newSelectedTemplates.push({
                    displayName: tmp.displayName,
                    enabled: tmp.enabled,
                    _id: tmp._id,
                    key: tmp.key,
                    order: tmp.order,
                    disable: true,
                    value: tmp.value,
                });
            } else {
                newSelectedTemplates.push({
                    displayName: tmp.displayName,
                    enabled: tmp.enabled,
                    _id: tmp._id,
                    key: tmp.key,
                    order: tmp.order,
                    disable: false,
                    value: tmp.value,
                });
            }
        });
        setPreSelectedTemplates([...newSelectedTemplates]);
    };
    // API Call

    const updateGroupName = () => {
        AccessManagementService.updateGroupName({
            groupId: memberDetails.groupId,
            name: groupName,
        }).then(() => {});
    };
    const updatePolicy = () => {
        setLoader(true);
        const templatesEnable = [];
        templates.forEach((template) =>
            templatesEnable.push({
                templateId: template._id,
                enabled: template.enabled,
            })
        );
        const autoRedactionTemplateEnable = [];
        autoRedactiontemplates.forEach((template) =>
            autoRedactionTemplateEnable.push({
                templateId: template._id,
                enabled: template.enabled,
            })
        );
        const PreSelectedTemplateEnable = [];
        preSelectedTemplates.forEach((template) =>
            PreSelectedTemplateEnable.push({
                templateId: template._id,
                enabled: template.enabled,
            })
        );

        AccessManagementService.updatePolicy({
            groupId: memberDetails.groupId,
            autoRedactionEnabled: autoRedactionChecked,
            customRedactionEnabled: customRedactionChecked,
            percentageRedactionEnabled: true,
            drawRedactionEnabled: isDrawRedactionEnabled,
            templateRedactionEnabled: isTemplateRedactionEnabled,
            templates: templatesEnable,
            autoRedactionTemplates: autoRedactionTemplateEnable,
            preselectedTemplates: PreSelectedTemplateEnable,
        })
            .then((response) => {
                Utils.handleSuccessRepsonse(response);
                setLoader(false);
                setShowEditModel(false);
                loadPolicyMembersHistory();
            })
            .catch((error) => {
                Utils.handleErrorResponse({ error });
                setLoader(false);
                setShowEditModel(false);
            });
    };
    // Validate Template
    const validateTemplate = () => {
        const disableTemplate = [];
        templates.forEach((tmp) => {
            if (!tmp.enabled) {
                disableTemplate.push({
                    enable: tmp.enabled,
                });
            }
        });
        const disableAutoRedactionTemp = [];
        autoRedactiontemplates.forEach((tmp) => {
            if (!tmp.enabled) {
                disableAutoRedactionTemp.push({
                    enable: tmp.enabled,
                });
            }
        });
        const disablePreSelectTemplate = [];
        preSelectedTemplates.forEach((tmp) => {
            if (!tmp.enabled) {
                disablePreSelectTemplate.push({
                    enable: tmp.enabled,
                });
            }
        });

        if (
            autoRedactionChecked &&
            autoRedactiontemplates.length === disableAutoRedactionTemp.length
        ) {
            toast.info("Please select atleast One Template");
        } else if (
            (isTemplateRedactionEnabled &&
                templates.length === disableTemplate.length) ||
            preSelectedTemplates.length === disablePreSelectTemplate.length
        ) {
            toast.info("Please select atleast One Template");
        } else {
            if (memberDetails.name !== groupName) {
                updateGroupName();
            }
            updatePolicy();
        }
    };

    useEffect(() => {
        if (InitialLoad) {
            initialPreSelectList();
        } else {
            preSelectedList(templates);
        }
    }, [templates]);

    // UI Elements
    return (
        <>
            <Box className="page-content1 ">
                <Box className="access-management-heading ">
                    <Typography className="ml-7 edit-heading">
                        Access Management
                    </Typography>
                    <Box className="modal-close-icon py-3 px-3">
                        <img
                            src="/images/icons/icon_close.png"
                            alt="close"
                            width="25"
                            onClick={() => {
                                setShowEditModel(false);
                            }}
                            className="mr-3 pl-3 mt-2"
                        />
                    </Box>
                </Box>
            </Box>
            <Box className="edit-modal-container px-7 py-4">
                <Typography variant="h6" className="title-color mb-1">
                    Group Name
                </Typography>
                {/** group Name */}
                <TextField
                    className="group-name-style"
                    variant="outlined"
                    size="small"
                    fullWidth
                    placeholder="Group Name"
                    value={groupName}
                    onChange={(e) => setGroupName(e.target.value)}
                />

                <Typography variant="h6" className="title-color mb-2 mt-2">
                    Functionalities
                </Typography>

                {memberDetails && (
                    <>
                        {/** Auto Redaction  */}
                        <Box
                            className={`${
                                autoRedactionChecked
                                    ? "functionality-box"
                                    : "functionality-box-disable"
                            }`}
                        >
                            <Typography className="functionality-heading ">
                                Auto Redaction
                            </Typography>

                            <AppCustomSwitchBox
                                onChange={(e) => {
                                    handlePolicyChange(
                                        e,
                                        setAutoRedactionChecked
                                    );
                                    // if (e.target.checked) {
                                    //     setIsTemplateRedactionEnabled(
                                    //         e.target.checked
                                    //     );
                                    // }
                                }}
                                checked={autoRedactionChecked}
                                disabled={loader || !showSaveButton}
                            />
                        </Box>

                        {/** Custom Words  */}
                        <Box
                            className={`${
                                customRedactionChecked
                                    ? "functionality-box mt-2"
                                    : "functionality-box-disable mt-2"
                            }`}
                        >
                            <Typography className="functionality-heading ">
                                Enter Custom Words functionality
                            </Typography>

                            <AppCustomSwitchBox
                                onChange={(e) => {
                                    handlePolicyChange(
                                        e,
                                        setCustomRedactionChecked
                                    );
                                }}
                                checked={customRedactionChecked}
                                disabled={loader || !showSaveButton}
                            />
                        </Box>
                        {/** Custom Redaction   */}
                        <Box
                            className={`${
                                isDrawRedactionEnabled
                                    ? "functionality-box mt-2"
                                    : "functionality-box-disable mt-2"
                            }`}
                        >
                            <Typography className="functionality-heading ">
                                Draw Custom Redaction
                            </Typography>

                            <AppCustomSwitchBox
                                onChange={(e) => {
                                    handlePolicyChange(
                                        e,
                                        setIsDrawRedactionEnabled
                                    );
                                }}
                                checked={isDrawRedactionEnabled}
                                disabled={loader || !showSaveButton}
                            />
                        </Box>

                        {/** Redaction Templates   */}
                        <Box
                            className={`${
                                isTemplateRedactionEnabled
                                    ? "functionality-box mt-2"
                                    : "functionality-box-disable mt-2"
                            }`}
                        >
                            <Typography className="functionality-heading">
                                Redaction Templates
                            </Typography>

                            <AppCustomSwitchBox
                                checked={isTemplateRedactionEnabled}
                                onChange={(e) => {
                                    setIsTemplateRedactionEnabled(
                                        e.target.checked
                                    );
                                    if (isTemplateRedactionEnabled) {
                                        setIsPercentageRedactionEnabled(false);
                                    }
                                }}
                                disabled={loader || !showSaveButton}
                            />
                        </Box>
                        {/** Redaction Percentage  */}
                        {false && (
                            <Box
                                className={`${
                                    isPercentageRedactionEnabled
                                        ? "functionality-box mt-2"
                                        : "functionality-box-disable mt-2"
                                }`}
                            >
                                <Typography className="functionality-heading ">
                                    Redaction Percentage functionality
                                </Typography>

                                <AppCustomSwitchBox
                                    onChange={(e) => {
                                        handlePolicyChange(
                                            e,
                                            setIsPercentageRedactionEnabled
                                        );
                                    }}
                                    checked={isPercentageRedactionEnabled}
                                    disabled={
                                        loader ||
                                        !isTemplateRedactionEnabled ||
                                        !showSaveButton
                                    }
                                />
                            </Box>
                        )}
                    </>
                )}
                {/** AutoRedaction Templates */}

                {autoRedactionChecked && autoRedactiontemplates.length > 0 && (
                    <>
                        <Box className="redaction-template-container mt-9 mb-1">
                            <Typography variant="h6" className="title-color">
                                Auto Redaction Templates
                            </Typography>
                            <FormControlLabel
                                control={
                                    <AppCheckBox
                                        checked={activateAllAutoTemp}
                                        onChange={(event) => {
                                            setActivateAllAutoTemp(
                                                event.target.checked
                                            );
                                            onChangeActivateAllAutoRedaction(
                                                event.target.checked
                                            );
                                        }}
                                        disabled={loader || !showSaveButton}
                                        className="auto-renew-checkbox"
                                    />
                                }
                                label={
                                    <Typography
                                        variant="body2"
                                        className="text-secondary"
                                    >
                                        Activate all
                                    </Typography>
                                }
                            />
                        </Box>

                        {policyDetails.autoRedactionTemplates.map((temp, i) => (
                            <>
                                <Box
                                    className={`${
                                        temp.enabled
                                            ? "functionality-box mb-2"
                                            : "functionality-box-disable mb-2"
                                    }`}
                                >
                                    <Typography className="functionality-heading ">
                                        {temp.displayName}
                                    </Typography>

                                    <AppCustomSwitchBox
                                        onChange={(e) => {
                                            handleChangeAutoRedactionTemplate(
                                                e,
                                                i
                                            );
                                        }}
                                        checked={temp.enabled}
                                        disabled={loader || !showSaveButton}
                                    />
                                </Box>
                            </>
                        ))}
                    </>
                )}

                {isTemplateRedactionEnabled && (
                    <>
                        <Box className="redaction-template-container mt-9">
                            <Typography variant="h6" className="title-color">
                                Redaction Templates
                            </Typography>
                            <FormControlLabel
                                control={
                                    <AppCheckBox
                                        checked={activateAll}
                                        onChange={(event) => {
                                            setActivateAll(
                                                event.target.checked
                                            );
                                            onChangeActivateAll(
                                                event.target.checked
                                            );
                                            setInitialLoad(false);
                                        }}
                                        disabled={loader || !showSaveButton}
                                        className="auto-renew-checkbox"
                                    />
                                }
                                label={
                                    <Typography
                                        variant="body2"
                                        className="text-secondary"
                                    >
                                        Activate all
                                    </Typography>
                                }
                            />
                        </Box>

                        {policyDetails.templates.map((temp, i) => (
                            <>
                                <Box
                                    className={`${
                                        temp.enabled
                                            ? "functionality-box mb-2"
                                            : "functionality-box-disable mb-2"
                                    }`}
                                >
                                    <Typography className="functionality-heading ">
                                        {temp.displayName}
                                    </Typography>

                                    <AppCustomSwitchBox
                                        onChange={(e) => {
                                            handleChangeTemplate(e, i);
                                            setInitialLoad(false);
                                        }}
                                        checked={temp.enabled}
                                        disabled={loader || !showSaveButton}
                                    />
                                </Box>
                            </>
                        ))}
                    </>
                )}
                {/** Preselected Templates */}

                {isTemplateRedactionEnabled &&
                    preSelectedTemplates.length > 0 && (
                        <>
                            <Box className="redaction-template-container mt-9 mb-1">
                                <Typography
                                    variant="h6"
                                    className="title-color"
                                >
                                    Preselected Templates
                                </Typography>
                                <FormControlLabel
                                    control={
                                        <AppCheckBox
                                            checked={activateAllPreDefinedTemp}
                                            onChange={(event) => {
                                                setactivateAllPreDefinedTemp(
                                                    event.target.checked
                                                );
                                                onChangeActivateAllPreDefinedTemp(
                                                    event.target.checked
                                                );
                                            }}
                                            disabled={loader || !showSaveButton}
                                            className="auto-renew-checkbox"
                                        />
                                    }
                                    label={
                                        <Typography
                                            variant="body2"
                                            className="text-secondary"
                                        >
                                            Activate all
                                        </Typography>
                                    }
                                />
                            </Box>
                            {/**predefined temp */}
                            {preSelectedTemplates.length > 0 &&
                                preSelectedTemplates.map((temp, i) => (
                                    <>
                                        <Box
                                            className={`${
                                                temp.enabled
                                                    ? "functionality-box mb-2"
                                                    : "functionality-box-disable mb-2"
                                            }`}
                                        >
                                            <Typography className="functionality-heading ">
                                                {temp.displayName}
                                            </Typography>

                                            <AppCustomSwitchBox
                                                onChange={(e) => {
                                                    handleChangePreDefinedTemp(
                                                        e,
                                                        i
                                                    );
                                                }}
                                                checked={temp.enabled}
                                                disabled={
                                                    loader ||
                                                    !showSaveButton ||
                                                    !temp.disable
                                                }
                                            />
                                        </Box>
                                    </>
                                ))}
                        </>
                    )}
            </Box>
            <Divider className="mt-4" />
            {showSaveButton && (
                <Box className="btn-save-changes py-3 px-7">
                    {/* <Button
                        title="Save changes"
                        className="btn-small policy-update-btn"
                        onClick={() => {
                            validateTemplate();
                        }}
                        isLoading={loader}
                        disabled={loader}
                        loadingText="saving"
                    /> */}
                    <Button
                        className="btn-small policy-update-btn"
                        onClick={() => {
                            validateTemplate();
                        }}
                        disabled={loader}
                    >
                        Save changes
                    </Button>
                </Box>
            )}
        </>
    );
};
export default AccessManageEditModal;
