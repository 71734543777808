import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Typography } from "@material-ui/core";
import DocumentUpload from "./components/document-upload";
import RedactHistory from "./components/redact-history";
import BulkDownload from "./components/bulk-download";

const RedactDocument = (props) => {
    const [maxFiles] = useState(1);
    const redactHistoryComponent = useRef();

    //UI Actions
    const onNewFileUploaded = () => {
        if (redactHistoryComponent && redactHistoryComponent.current) {
            redactHistoryComponent.current.refreshHistory();
        }
    };

    //UI Elements
    return (
        <>
            <Box className="title-header">
                <Typography
                    className="f24 myriadpro-slim white mx-5 py-4"
                    variant="h1"
                >
                    ANZ Redact
                </Typography>
            </Box>
            <Box p={5}>
                <Box className="upload-card">
                    <Box px={3} pb={3} pt={2}>
                        <DocumentUpload
                            maxFiles={maxFiles}
                            onNewFileUploaded={onNewFileUploaded}
                        />
                    </Box>
                    <Box className="p-3 bulk-divider">
                        <BulkDownload loadHistory={onNewFileUploaded} />
                    </Box>
                    <RedactHistory ref={redactHistoryComponent} />
                </Box>
            </Box>
        </>
    );
};

export default RedactDocument;
