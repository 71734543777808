import ApiManager from "../shared/managers/api-manager";
import { RestEnds } from "../shared/constants";

const memberPolicyHistory = async (limit, offset, text) => {
    const url = `${RestEnds.POLICY_HISTORY}?limit=${limit}&offset=${offset}&query=${text}`;
    return ApiManager.sendGet(url, true);
};
const GroupPolicyHistory = async (limit, offset) => {
    const url = `${RestEnds.GROUP_POLICY_HISTORY}?offset=${offset}&limit=${limit}`;
    return ApiManager.sendGet(url, true);
};

const usersHistory = async (limit, offset, query, groupId) => {
    const url = `${RestEnds.GROUP_USERS_HISTORY}?offset=${offset}&limit=${limit}&query=${query}&groupId=${groupId}`;
    return ApiManager.sendGet(url, true);
};

const allGroups = async () => {
    const url = `${RestEnds.ALL_GROUPS}?offset=1&limit=1000`;
    return ApiManager.sendGet(url, true);
};
const updatePolicy = async (data) =>
    ApiManager.sendPost(RestEnds.UPDATE_POLICY, data, true);

const updateGroupName = async (data) =>
    ApiManager.sendPatch(RestEnds.UPDATE_GROUP_NAME, data, true);

const templateList = async () =>
    ApiManager.sendGet(RestEnds.TEMPLATE_LIST, true);
const getPolicyConfig = async () =>
    ApiManager.sendGet(RestEnds.POLICY_CONFIG, true);

export const AccessManagementService = {
    memberPolicyHistory,
    updatePolicy,
    templateList,
    getPolicyConfig,
    GroupPolicyHistory,
    updateGroupName,
    allGroups,
    usersHistory,
};
