import { logoutUser } from "../../state/authentication";
import store from "../../state/rootReducer";
let timeOutMins = 1;
let timer;

const startTimer = () => {
    // console.log("New timer started");
    clearTimeout(timer);
    timer = setTimeout(logout, timeOutMins * 60 * 1000);
};

const logout = () => {
    // console.log("Going to logout");
    store.dispatch(logoutUser({}));
    window.location.href = store.getState().authState.url.logout;
};

const logoutOnIdleTimeOut = (durationMins) => {
    // console.log("Enabling idle check");
    timeOutMins = parseInt(durationMins);
    let events = ["mousedown", "mousemove", "keypress", "scroll", "touchstart"];
    events.forEach((name) => {
        document.addEventListener(name, startTimer, true);
    });
};

export default { logoutOnIdleTimeOut };
