import { useSelector } from "react-redux";
import store from "../state/rootReducer";
import { setHistoryTableState } from "../state/shared";

export const useHistoryPagination = () => {
    const pagination = useSelector(
        (state) => state.sharedState["historyTable"]
    );
    const setPagination = (newPagination) => {
        store.dispatch(setHistoryTableState(newPagination));
    };
    return [pagination, setPagination];
};
