const PreferenceKeys = {
    ACCESS_TOKEN: "ACCESS_TOKEN",
    REFRESH_TOKEN: "REFRESH_TOKEN",
    TWO_FA_ENABLE: "TWO_FACT_AUTH",
    TWO_FA_TOKEN: "TWO_FACT_TOKEN",
    LOGIN_WITH: "LOGIN_WITH",
    CATEGORY: "CATEGORY",
};

class AppPreference {
    setInStorage(key, value) {
        localStorage.setItem(key.toString(), value);
    }

    getFromStorage(key) {
        return localStorage.getItem(key.toString());
    }

    deleteFromPreference(key) {
        localStorage.removeItem(key.toString());
    }

    setObject(key, object) {
        const objectStr = JSON.stringify(object);
        this.setInStorage(key, objectStr);
    }

    getObject(key) {
        const objectStr = this.getFromStorage(key);
        if (objectStr) {
            return JSON.parse(objectStr);
        }
        return null;
    }

    clearStorage() {
        localStorage.clear();
    }

    clearToken() {
        this.deleteFromPreference(PreferenceKeys.ACCESS_TOKEN);
        this.deleteFromPreference(PreferenceKeys.REFRESH_TOKEN);
        localStorage.clear();
    }

    setAccessToken(accessToken) {
        this.setInStorage(PreferenceKeys.ACCESS_TOKEN, accessToken);
    }

    getAccessToken() {
        return this.getFromStorage(PreferenceKeys.ACCESS_TOKEN);
    }

    setRefreshToken(refreshToken) {
        this.setInStorage(PreferenceKeys.REFRESH_TOKEN, refreshToken);
    }

    getRefreshToken() {
        return this.getFromStorage(PreferenceKeys.REFRESH_TOKEN);
    }
}

export default new AppPreference();
