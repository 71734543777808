import { Box, Container, Typography } from "@material-ui/core";

const UnderMaintenance = () => (
    <Box
        style={{
            backgroundColor: "background.default",
            display: "flex",
            flexDirection: "column",
            height: "100%",
            justifyContent: "center",
            padding: "50px",
        }}
    >
        <Container maxWidth="md">
            <Typography align="center" color="textPrimary" variant="h4">
                We will be back soon
            </Typography>
            <Box sx={{ textAlign: "center" }}>
                <img
                    alt="Under development"
                    src="/images/site-down.jpeg"
                    style={{
                        marginTop: 50,
                        display: "inline-block",
                        width: "100%",
                    }}
                />
            </Box>
        </Container>
    </Box>
);

export default UnderMaintenance;
