import { withStyles } from "@material-ui/core/styles";
import { Switch } from "@material-ui/core";

const AppCustomSwitchBox = withStyles((theme) => {
    const { palette } = theme;
    return {
        root: {
            width: 38,
            height: 20,
            padding: 0,
        },
        switchBase: {
            padding: 2,
            "&$checked": {
                transform: "translateX(19px)",
                color: "#ffffff",

                "& + $track": {
                    backgroundColor: palette.primary.main,
                    opacity: 1,
                    border: "none",
                },
            },
            "&$focusVisible $thumb": {
                color: palette.primary.main,
                border: "6px solid #fff",
            },
        },
        thumb: {
            width: 15,
            height: 15,
            borderRadius: 1,
        },
        track: {
            borderRadius: 3,
            // border: `1px solid ${theme.palette.grey[400]}`,
            backgroundColor: "#dcdde2",
            opacity: 1,
            // transition: transitions.create([
            //     'background-color',
            //     'border',
            // ]),
        },
        checked: {},
        focusVisible: {},
    };
})((props) => {
    const { classes } = props;
    return (
        <Switch
            focusVisibleClassName={classes.focusVisible}
            disableRipple
            classes={{
                root: classes.root,
                switchBase: classes.switchBase,
                thumb: classes.thumb,
                track: classes.track,
                checked: classes.checked,
            }}
            {...props}
        />
    );
});

export default AppCustomSwitchBox;
