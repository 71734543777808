import update from "immutability-helper";
import AppPreference from "../shared/managers/app-preference";

const authState = {
    isUserLoggedIn: false,
    accessToken: "",
    refreshToken: "",
    name: "",
    email: "",
    userId: "",
    imageUrl: "",
    role: "",
    url: {
        apps: "",
        logout: "",
    },
    sessionIdleInMins: "0",
};

const AUTH_SET_LOGIN_DATA = "AUTH_SET_LOGIN_DATA";
const AUTH_LOGOUT_USER = "AUTH_LOGOUT_USER";
const AUTH_SET_APP_URLS = "AUTH_SET_APP_URLS";

export const setLoginData = (payload) => ({
    type: AUTH_SET_LOGIN_DATA,
    payload,
});

export const logoutUser = (payload) => ({
    type: AUTH_LOGOUT_USER,
    payload,
});

export const setAppUrls = (payload) => ({
    type: AUTH_SET_APP_URLS,
    payload,
});

const AuthStateReducer = (state = authState, action) => {
    switch (action.type) {
        case AUTH_SET_LOGIN_DATA:
            AppPreference.setAccessToken(action.payload["accessToken"]);
            AppPreference.setRefreshToken(action.payload["refreshToken"]);
            return update(state, {
                $merge: {
                    ...action.payload,
                },
            });
        case AUTH_LOGOUT_USER:
            localStorage.clear();
            return update(state, {
                $merge: {
                    isUserLoggedIn: false,
                    accessToken: "",
                    refreshToken: "",
                    name: "",
                    email: "",
                    userId: "",
                    imageUrl: "",
                    role: "",
                    sessionIdleInMins: "0",
                },
            });
        case AUTH_SET_APP_URLS:
            return update(state, {
                $merge: {
                    ...action.payload,
                },
            });
        default:
            return state;
    }
};

export default AuthStateReducer;
