import axios from "./axios-manager";
const axiosManager = require("axios");

class APIManager {
    provider = "Axios";

    getProvider() {
        return axios;
    }

    getCancelSource() {
        const CancelToken = axiosManager.CancelToken;
        return CancelToken.source();
    }

    getConfig(auth, config = {}) {
        return {
            headers: {
                "append-auth-header": auth ? "true" : "false",
            },
            ...config,
        };
    }

    sendPost(url, postData, auth = false, config = {}) {
        return this.getProvider().post(
            url,
            postData,
            this.getConfig(auth, config)
        );
    }

    sendPut(url, postData, auth = false, config = {}) {
        return this.getProvider().put(
            url,
            postData,
            this.getConfig(auth, config)
        );
    }

    sendPatch(url, postData, auth = false, config = {}) {
        return this.getProvider().patch(
            url,
            postData,
            this.getConfig(auth, config)
        );
    }

    sendGet(url, auth = false, config = {}) {
        return this.getProvider().get(url, this.getConfig(auth, config));
    }

    sendDelete(url, auth = false, config = {}) {
        return this.getProvider().delete(url, this.getConfig(auth, config));
    }
}

export default new APIManager();
