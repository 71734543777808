import { Box, Container, Typography } from "@material-ui/core";

const NotFound = () => (
    <Box
        sx={{
            backgroundColor: "background.default",
            display: "flex",
            flexDirection: "column",
            height: "100%",
            justifyContent: "center",
        }}
    >
        <Container maxWidth="md">
            <Typography align="center" color="textPrimary" variant="h4">
                404: The page you are looking for isn’t here
            </Typography>
            <Typography align="center" color="textPrimary" variant="subtitle2">
                You either tried some shady route or you came here by mistake.
                Whichever it is, try using the navigation
            </Typography>
            <Box sx={{ textAlign: "center" }}>
                <img
                    alt="Under development"
                    src="/images/not-found.jpg"
                    style={{
                        marginTop: 50,
                        display: "inline-block",
                        width: "100%",
                    }}
                />
            </Box>
        </Container>
    </Box>
);

export default NotFound;
