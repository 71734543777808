import { Box, Button, Typography, Checkbox } from "@material-ui/core";
import { connect } from "react-redux";
import { useState, useRef } from "react";
import { Utils } from "../../../../shared/helpers";
import { RedactionService } from "../../../../services";
import moment from "moment";
import "moment-timezone";
import CircularProgress from "@material-ui/core/CircularProgress";
import SystemFileDownload from "../../../../shared/components/SystemFileDownload";
import AppCheckBox from "../../../../shared/components/AppCheckBox";

const BulkDownload = (props) => {
    const { selectedRequestDocuments, loadHistory } = props;
    const [reviewAll, setReviewAll] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const fileDownloader = useRef();

    // API Calls
    const loadSelectedDocuments = (callback) => {
        setIsLoading(true);
        const timeZone = moment.tz.guess();
        RedactionService.bulkDownloadSelected(
            selectedRequestDocuments.toString(),
            timeZone
        )
            .then((response) => {
                let bulkDownloadResponse = response["data"]["data"];
                callback(true, bulkDownloadResponse);
                setIsLoading(false);
            })
            .catch((error) => {
                Utils.handleRedactErrorResponse({ error });
                callback(false);
                setIsLoading(false);
            });
    };

    const loadRedactHistory = () => {
        loadHistory();
    };

    const downloadFiles = (files) => {
        if (fileDownloader && fileDownloader.current) {
            const downloader = fileDownloader.current;
            downloader.startDownFile({
                url: files,
                extension: "zip",
                documentType: "bulk",
            });
        }
    };

    const onDownloadSuccess = () => {
        loadRedactHistory();
    };

    //UI Actions
    const actionOnReviewAllDocuments = (event) => {
        setReviewAll(event.target.checked);
    };

    const actionOnBulkDownload = () => {
        loadSelectedDocuments((status, response) => {
            if (status) {
                downloadFiles(response);
                setReviewAll(false);
            }
        });
    };

    //UI Elements
    return (
        <>
            <Box display="flex" alignItems="center">
                <Box flexGrow={1}>
                    <Typography className="document-notes mb-1">
                        Documents from earlier today
                    </Typography>
                    <Typography className="f12 opensans-semi-bold">
                        Documents are retained until <b>11:59pm today</b> then
                        will be <b>permanently deleted</b>.
                    </Typography>
                </Box>

                <Box p={1} display="flex">
                    <AppCheckBox
                        checked={reviewAll}
                        onChange={actionOnReviewAllDocuments}
                        name="reviewAll"
                        areaLabel="Check this box to confirm that all documents have been reviewed."
                    />
                    {/* </Checkbox> */}
                    <Box display="flex" alignItems="center" flexGrow={1} pr={2}>
                        <Typography className="f12 opensans-semi-bold">
                            I confirm that all documents have been reviewed
                        </Typography>
                    </Box>
                    <Button
                        className="btn-download myriadpro-regular"
                        disabled={
                            selectedRequestDocuments.length > 0 &&
                            reviewAll === true
                                ? false
                                : true
                        }
                        startIcon={
                            <>
                                {isLoading && (
                                    <CircularProgress
                                        color="white"
                                        style={{
                                            width: "20px",
                                            height: "20px",
                                        }}
                                    />
                                )}
                            </>
                        }
                        onClick={actionOnBulkDownload}
                    >
                        {selectedRequestDocuments.length == 0
                            ? "Download"
                            : `Download (${selectedRequestDocuments.length})`}
                    </Button>
                </Box>
            </Box>

            <SystemFileDownload
                onDownloadSuccess={onDownloadSuccess}
                ref={fileDownloader}
            />
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        selectedRequestDocuments:
            state.redactDocumentState.selectedRequestDocuments,
    };
};

export default connect(mapStateToProps, null)(BulkDownload);
