import { useEffect } from "react";
import { Outlet } from "react-router";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
    Box,
    CircularProgress,
    Typography,
    Container,
} from "@material-ui/core";
import jwt_decode from "jwt-decode";
import { connect } from "react-redux";
import { setLoginData } from "../state/authentication";
import { AuthService } from "../services/authentication";
import { setWebSocketUrl } from "../state/redactDocument";
import { useHistoryPagination } from "../hooks";
const queryString = require("query-string");

const AuthenticationPage = (props) => {
    const { actionSetLoginData, setWebSocketUrl } = props;
    const location = useLocation();
    const navigate = useNavigate();
    const [pagination, setPagination] = useHistoryPagination();

    // Instance methods
    const authenticateToken = (token) => {
        try {
            const tokenData = jwt_decode(token);
            const { emailId, userId, name, role, imageUrl, sessionIdleInMins } =
                tokenData;
            const payload = {
                accessToken: token,
                isUserLoggedIn: true,
                name: name,
                email: emailId,
                userId: userId,
                imageUrl: imageUrl,
                role: role,
                sessionIdleInMins: sessionIdleInMins,
            };
            actionSetLoginData(payload);
            setTimeout(() => {
                AuthService.verifyAuthToken()
                    .then((response) => {
                        actionSetLoginData(response.data.data);
                        setTimeout(() => {
                            navigate("/redact", { replace: true });
                        }, 100);
                    })
                    .catch((error) => {
                        toast.error(
                            "Oop's something went wrong. Please try again"
                        );
                        navigate(-1, { replace: true });
                    });
            }, 1000);
        } catch (error) {
            toast.error(
                "Unable to validate the authentication. Please try again"
            );
            navigate(-1, { replace: true });
        }
    };

    // Hooks
    useEffect(() => {
        setPagination({ limit: 10, offset: 1, total: 0 });
        setWebSocketUrl("LOAD_FROM_SERVER");
    }, []);

    useEffect(() => {
        const queryParams = queryString.parse(location.search);
        if (queryParams["token"]) {
            authenticateToken(queryParams["token"]);
        } else {
            toast.error("Unable to authenticate. Please try again");
            navigate(-1, { replace: true });
        }
    }, [location.pathname]);

    // UI Elements
    return (
        <>
            <Box className="app-wrapper">
                <Container>
                    <Box className="welcome-header">
                        <img src={"/images/anz-logo.png"} width="100" alt="" />
                    </Box>
                    <Box
                        mt={3}
                        display="flex"
                        alignItems="center"
                        flexDirection="column"
                    >
                        <Box className="lato-regular f14 description">
                            <Typography className="white">
                                Redirecting to ANZ redaction service...
                            </Typography>
                        </Box>
                        <Box my={4}>
                            <CircularProgress className="white" />
                        </Box>
                    </Box>
                </Container>
            </Box>

            <Outlet></Outlet>
        </>
    );
};

const mapDispatchToProps = (dispatch) => ({
    actionSetLoginData: (data) => dispatch(setLoginData(data)),
    setWebSocketUrl: (data) => dispatch(setWebSocketUrl(data)),
});

export default connect(null, mapDispatchToProps)(AuthenticationPage);
