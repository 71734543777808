import { combineReducers } from "redux";
import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import AuthStateReducer from "./authentication";
import SubscriptionReducer from "./subsription";
import RedactDocumentReducer from "./redactDocument";
import SharedReducer from "./shared";
import PolicyReducer from "./policy";

import autoMergeLevel2 from "redux-persist/es/stateReconciler/autoMergeLevel2";

const appReducer = combineReducers({
    subscriptionState: SubscriptionReducer,
    redactDocumentState: RedactDocumentReducer,
    authState: AuthStateReducer,
    sharedState: SharedReducer,
    policyState: PolicyReducer,
});

const rootReducer = (state, action) => {
    if (action.type === "RESET_STORE") {
        storage.removeItem("persist:root");
        return appReducer(undefined, action);
    }
    return appReducer(state, action);
};

const persistConfig = {
    key: "root",
    storage,
    stateReconciler: autoMergeLevel2,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const middleware = [
    ...getDefaultMiddleware({
        immutableCheck: false,
        serializableCheck: false,
        thunk: true,
    }),
];

const store = configureStore({
    reducer: persistedReducer,
    middleware,
    devTools: process.env.NODE_ENV !== "production",
});
export const persistor = persistStore(store);
export default store;
