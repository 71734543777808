import { toast } from "react-toastify";

const getInitial = (name = "") =>
    name
        .replace(/\s+/, " ")
        .split(" ")
        .slice(0, 2)
        .map((v) => v && v[0].toUpperCase())
        .join("");

const handleSuccessRepsonse = (response) => {
    toast.success(response["data"]["msg"]);
};

const handleErrorResponse = ({ error }) => {
    if (error && error["response"]) {
        const { data } = error.response;
        if (data["msg"]) {
            toast.error(data["msg"]);
        } else {
            toast.error("Please try again");
        }
    }
};

const handleRedactErrorResponse = ({ error }) => {
    if (error && error["response"]) {
        const { data } = error.response;
        if (data["msg"]) {
            toast.error(data["msg"]);
        } else {
            toast.error("Please try again");
        }
    }
};

const fileExtension = (fileName) => {
    const url = fileName.split("?")[0];
    const extension = url.split(".").pop();
    return extension ? extension : "";
};

const fileNameWithoutExtension = (fileName) => {
    const extension = fileName.split(".").pop();
    fileName = fileName.replace(`.${extension}`, "");
    return fileName;
};

const hasMoreRecords = (pagination) => {
    return pagination.limit * pagination.offset < pagination.total;
};

const titleCase = (value) => {
    if (value) {
        return value
            .split(" ")
            .map((w) => w[0].toUpperCase() + w.substr(1).toLowerCase())
            .join(" ");
    } else {
        return "";
    }
};
const truncateMiddle = (str) => {
    if (str.length > 35) {
        return (
            str.substr(0, 15) + "..." + str.substr(str.length - 15, str.length)
        );
    }
    return str;
};

const truncateLast = (str) => {
    if (str.length > 30) {
        return str.substr(0, 30) + "...";
    }
    return str;
};

export const Utils = {
    getInitial,
    handleSuccessRepsonse,
    handleErrorResponse,
    handleRedactErrorResponse,
    fileExtension,
    hasMoreRecords,
    titleCase,
    fileNameWithoutExtension,
    truncateMiddle,
    truncateLast,
};
