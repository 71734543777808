import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
    Table,
    TableBody,
    TableCell,
    TableRow,
    Button,
    Box,
    Container,
    Card,
    CardContent,
    Typography,
    InputLabel,
    MenuItem,
    Select,
    FormControl,
    ListItemText,
    Checkbox,
} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { CSVLink } from "react-csv";
import moment from "moment";
import { addDays, subDays } from "date-fns";
import { Utils } from "../../../../shared/helpers";
import { AppDatePicker } from "../../../../shared/components/AppComponents";
import { AccessManagementService, ReportService } from "../../../../services";

const Report = () => {
    const [groups, setGroups] = useState([]);
    const [groupIds, setGroupIds] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const [reportData, setReportData] = useState({
        totalNoOfDocumentsRedacted: 0,
        totalNoOfDocuments: 0,
        totalNoOfOldDocuments: 0,
        totalPagesCount: 0,
        reRedactedCount: 0,
        templateRedactionUsedCount: 0,
        customKeywordRedactionUsedCount: 0,
        paintBrushUsedCount: 0,
        failedRedactionCount: 0,
        unsuccessfullCount: 0,
        purgedRedactionCount: 0,
        totalCompletedRedactionPagesCount: 0,
    });
    const defaultFromDate = subDays(new Date(), 7);
    const [fromDate, setFromDate] = useState(defaultFromDate);
    const [toDate, setToDate] = useState(new Date());

    const headers = [
        { label: "Title", key: "title" },
        { label: "Counts", key: "counts" },
        {
            label: `Report for the period (From : ${moment(fromDate).format(
                "DD-MM-YYYY"
            )} To :  ${moment(toDate).format("DD-MM-YYYY")})`,
            key: "reportDate",
        },
    ];
    let fromdate = moment(fromDate).format("MM-DD-YYYY");
    let todate = moment(toDate).format("MM-DD-YYYY");

    // Hooks
    useEffect(() => {
        loadGroups();
    }, []);
    useEffect(() => {
        loadReport();
    }, [fromDate, toDate, groupIds]);

    //API Calls
    const loadGroups = () => {
        AccessManagementService.allGroups()
            .then((response) => {
                let { groupList } = response["data"]["data"];
                setGroups(groupList);
            })
            .catch((error) => {});
    };
    const loadReport = () => {
        setIsLoading(true);
        const selectedGroupIds = groupIds.map((s) => s.groupId);
        ReportService.getReports(fromdate, todate, selectedGroupIds.toString())
            .then((response) => {
                let reportResponse = response["data"]["data"]["report"];
                setReportData(reportResponse);
                setIsLoading(false);
            })
            .catch((error) => {
                Utils.handleRedactErrorResponse({ error });
                setIsLoading(false);
            });
    };

    // UI Actions
    const createData = (title, counts) => {
        return { title, counts };
    };

    const {
        totalNoOfDocumentsRedacted,
        totalNoOfDocuments,
        totalPagesCount,
        reRedactedCount,
        templateRedactionUsedCount,
        customKeywordRedactionUsedCount,
        paintBrushUsedCount,
        failedRedactionCount,
        unsuccessfullCount,
        purgedRedactionCount,
        totalCompletedRedactionPagesCount,
    } = reportData;

    const rows = [
        createData("Total number of Documents", totalNoOfDocuments),
        createData(
            "Total number of Redacted Documents",
            totalNoOfDocumentsRedacted
        ),
        createData("Total number of Re-Redacted Documents", reRedactedCount),

        createData("Total number of Pages", totalPagesCount),
        createData(
            "Total number of Redacted pages",
            totalCompletedRedactionPagesCount
        ),
        createData(
            "Total number of Template Redaction",
            templateRedactionUsedCount
        ),
        createData(
            "Total number of Keyword Redaction",
            customKeywordRedactionUsedCount
        ),
        createData("Total number of Custom Redaction", paintBrushUsedCount),
        createData("Total number of Failed Redaction", failedRedactionCount),
        createData(
            "Total number of Unsuccessful Redaction",
            unsuccessfullCount
        ),
        createData("Total number of Purged Documents", purgedRedactionCount),
    ];

    const csvReport = {
        data: rows,
        headers: headers,
        filename: "ANZ_Report.csv",
    };

    const actionClose = () => {
        navigate("/redact");
    };

    // UI Elements
    return (
        <>
            <Container maxWidth="md">
                <Box className="justify-end" pt={3}>
                    <Box className="group-box mr-2">
                        <InputLabel className="f14 myriadpro-regular text-center mr-2">
                            Groups
                        </InputLabel>
                        <FormControl variant="outlined" size="small">
                            <InputLabel>
                                {groupIds.length == 0
                                    ? "Select Group"
                                    : undefined}
                            </InputLabel>
                            <Select
                                label={
                                    groupIds.length == 0
                                        ? "Select Group"
                                        : undefined
                                }
                                className="group-selection"
                                multiple={true}
                                value={groupIds}
                                onChange={(e) => {
                                    const values = e.target.value;
                                    setGroupIds(values);
                                }}
                                renderValue={(selected) => {
                                    const selectedNames = selected.map(
                                        (s) => s.name
                                    );
                                    return selectedNames.toString();
                                }}
                            >
                                <MenuItem disabled>Select Group</MenuItem>
                                <MenuItem>
                                    <Box
                                        className="select-all"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            if (
                                                groupIds.length == groups.length
                                            ) {
                                                setGroupIds([]);
                                            } else {
                                                setGroupIds(groups);
                                            }
                                        }}
                                    >
                                        <Checkbox
                                            checked={
                                                groupIds.length == groups.length
                                            }
                                        />
                                        <ListItemText primary="Select All" />
                                    </Box>
                                </MenuItem>
                                {groups.map((group) => (
                                    <MenuItem value={group}>
                                        <Checkbox
                                            checked={groupIds.some(
                                                (g) =>
                                                    g.groupId === group.groupId
                                            )}
                                        />
                                        <ListItemText primary={group.name} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                    <Box>
                        <AppDatePicker
                            size="small"
                            label="From Date"
                            placeholder="From Date"
                            name="fromDate"
                            value={fromDate}
                            onChange={(date) => {
                                setFromDate(date);
                            }}
                            maxDate={subDays(new Date(), 0)}
                        ></AppDatePicker>
                    </Box>

                    <Box>
                        <AppDatePicker
                            size="small"
                            label="To Date"
                            placeholder="To Date"
                            name="toDate"
                            value={toDate}
                            onChange={(date) => {
                                setToDate(date);
                            }}
                            minDate={fromDate}
                            maxDate={addDays(new Date(), 0)}
                        ></AppDatePicker>
                    </Box>
                    <Box>
                        <Button variant="contained" color="secondary">
                            <CSVLink
                                {...csvReport}
                                className="f14 cursor-pointer myriadpro-regular white"
                            >
                                Export
                            </CSVLink>
                        </Button>
                    </Box>
                </Box>
                <Box mt={1} mb={5}>
                    <Card>
                        <CardContent>
                            <Box pt={1} pb={2} flexGrow={1}>
                                <Typography className="f20 myriadpro-semi-bold">
                                    ANZ Redaction Report
                                </Typography>
                            </Box>

                            <Table className="app-table">
                                <TableBody>
                                    {rows.map((row) => (
                                        <TableRow>
                                            <TableCell style={{ width: 300 }}>
                                                {row.title}
                                            </TableCell>
                                            <TableCell>{row.counts}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </CardContent>
                    </Card>
                </Box>
            </Container>
        </>
    );
};

export default Report;
