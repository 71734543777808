import ApiManager from "../shared/managers/api-manager";
import { RestEnds } from "../shared/constants";

const selectedDocuments = [];

const websocketURL = "";

const clearDocuments = () => {
    if (selectedDocuments.length > 0) {
        selectedDocuments.pop();
    }
};

const redactDocument = async (data, timeZone) => {
    return ApiManager.sendPost(RestEnds.REDACT_DOCUMENT(timeZone), data, true);
};

const redactHistory = async (limit, offset, timeZone) => {
    const url = `${RestEnds.REDACT_HISTORY}?limit=${limit}&offset=${offset}&timezone=${timeZone}`;
    return ApiManager.sendGet(url, true);
};

const deleteHistory = async (data) => {
    return ApiManager.sendPatch(RestEnds.DELETE_HISTORY, data, true);
};

const bulkDownload = async (allReviewed, timeZone) => {
    return ApiManager.sendGet(
        RestEnds.BULK_DOWNLOAD(allReviewed, timeZone),
        true
    );
};

const bulkDownloadSelected = async (bulkDownloadSelectedFiles, timeZone) => {
    return ApiManager.sendGet(
        RestEnds.BULK_DOWNLOAD_SELECTED(bulkDownloadSelectedFiles, timeZone),
        true
    );
};

const reviewDocument = async (data) => {
    return ApiManager.sendPatch(RestEnds.REVIEW_DOCUMENT, data, true);
};

const upload = async (data, config) => {
    return ApiManager.sendPost(RestEnds.UPLOAD, data, true, config);
};

const getWebSocketUrl = async () => {
    const url = `${RestEnds.WEBSOCKET_URL}`;
    return ApiManager.sendGet(url, true);
};

export const RedactionService = {
    redactDocument,
    redactHistory,
    bulkDownload,
    reviewDocument,
    selectedDocuments,
    clearDocuments,
    bulkDownloadSelected,
    upload,
    deleteHistory,
    getWebSocketUrl,
    websocketURL,
};
