const REDACT_PREVIEW_ELEMENT = "redact-preview-section";
const getEditorDimension = (img) => {
    const previewElement = document.getElementsByClassName(
        REDACT_PREVIEW_ELEMENT
    );
    const marginOffset = 20;
    if (previewElement.length > 0) {
        // const previewHeight = previewElement[0].offsetHeight - marginOffset;
        const previewWidth = previewElement[0].offsetWidth - marginOffset;
        // let canvasScale = previewHeight / img.height;
        // if (img.width * canvasScale > previewWidth) {
        let canvasScale = (previewWidth - 300) / img.width;
        // }
        const height = img.height * canvasScale;
        const width = img.width * canvasScale;
        return {
            width,
            height,
            canvasScale,
        };
    }
    return {
        width: 0,
        height: 0,
        canvasScale: 1,
    };
};

export { REDACT_PREVIEW_ELEMENT, getEditorDimension };
