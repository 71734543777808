import React, { useEffect } from "react";
import { Box } from "@material-ui/core";
import { Outlet } from "react-router-dom";
import { Scrollbars } from "react-custom-scrollbars";
import AppHeader from "../../shared/components/AppHeader";
import { IdleCheck } from "../../shared/helpers";
import { useSelector } from "react-redux";

const Home = () => {
    const sessionIdleInMins = useSelector(
        (state) => state.authState.sessionIdleInMins
    );

    useEffect(() => {
        if (parseInt(sessionIdleInMins) > 0) {
            IdleCheck.logoutOnIdleTimeOut(sessionIdleInMins);
        }
    }, [sessionIdleInMins]);

    // UI Elements
    return (
        <Box className="app-container overflowX">
            <Box className="app-header">
                <AppHeader />
            </Box>
            <main role="main" className="app-body">
                <Outlet></Outlet>
            </main>
        </Box>
    );
};

export default Home;
