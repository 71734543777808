import {
    Box,
    Dialog,
    DialogContent,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableRow,
    Typography,
    TextField,
    IconButton,
    Button,
} from "@material-ui/core";
import { AccessManagementService, DefaultPagination } from "../../../services";
import { useState, useEffect } from "react";
import EmptyList from "../../../shared/components/EmptyList";
import ClearIcon from "@material-ui/icons/Clear";
import SearchIcon from "@material-ui/icons/Search";
import { useDebouncedEffect } from "../../../shared/hooks";
import { CSVLink } from "react-csv";

const UsersList = (props) => {
    const { activeGroupId } = props;
    const [usersList, setUsersList] = useState([]);
    const [loadingUsersList, setloadingUsersList] = useState(false);
    const [paginations, setPagination] = useState(DefaultPagination(10));
    const [isUsersLoaded, setIsUsersLoaded] = useState(false);
    const [searchValue, setSearchValue] = useState("");
    const [exportusersList, setExportusersList] = useState([]);
    const [totalRecord, setTotalRecord] = useState(1000);

    const headers = [
        { label: "User ID", key: "userId" },
        { label: "User Name", key: "userName" },
        { label: "Email", key: "email" },
        { label: "Group Name", key: "groupName" },
        { label: "Role", key: "role" },
    ];

    // API Call
    const loadPolicyMembersHistory = () => {
        setloadingUsersList(true);
        AccessManagementService.usersHistory(
            paginations.limit,
            paginations.offset,
            searchValue,
            activeGroupId ? activeGroupId : ""
        )
            .then((response) => {
                const { users, pagination } = response.data.data;
                setTotalRecord(pagination.total);
                setUsersList(users);
                setPagination(pagination);
                setloadingUsersList(false);
                setIsUsersLoaded(true);
            })
            .catch(() => {
                setloadingUsersList(false);
                setIsUsersLoaded(true);
            });
    };

    const loadAllUsersList = () => {
        AccessManagementService.usersHistory(
            totalRecord,
            1,
            searchValue,
            ""
        ).then((response) => {
            const { users } = response.data.data;
            const reportList = [];
            users.forEach((report) => {
                reportList.push({
                    userId: report.userId,
                    userName: report.name,
                    email: report.email,
                    groupName: report.groupName,
                    role: getRoles(report.role),
                });
            });
            setExportusersList(reportList);
        });
    };

    //  UI Actions
    const actionHandleChangePage = (_event, newPage) => {
        paginations.offset = newPage + 1;
        loadPolicyMembersHistory();
    };

    const actionHandleChangeRowsPerPage = (event) => {
        paginations.offset = 1;
        paginations.limit = parseInt(event.target.value, 10);
        loadPolicyMembersHistory();
    };

    const onMemberSearch = (e) => {
        setloadingUsersList(true);
        const searchTerm = e.target.value;
        setSearchValue(searchTerm);
    };
    const actionOnClearSearch = () => {
        if (searchValue !== "") {
            setSearchValue("");
        }
    };

    const getRoles = (keyword) => {
        switch (keyword) {
            case "admin":
                return "Admin";
            case "user":
                return "User";
            default:
                break;
        }
    };

    // Hooks
    useDebouncedEffect(
        () => {
            loadPolicyMembersHistory(1, 10, searchValue);
        },
        [searchValue],
        1000
    );

    useEffect(() => {
        if (totalRecord) {
            loadAllUsersList(totalRecord, 1, "", "");
        }
    }, [totalRecord]);

    const csvReport = {
        data: exportusersList,
        headers: headers,
        filename: "ANZ_Users.csv",
    };

    return (
        <>
            <Box className="accessManagement-container px-6">
                <Box className="userlist-header">
                    <Box className="search-box-container">
                        <TextField
                            className="search-box  mt-2"
                            variant="outlined"
                            size="small"
                            placeholder="Search by name or email"
                            value={searchValue}
                            onChange={(e) => onMemberSearch(e)}
                            InputProps={{
                                startAdornment: (
                                    <SearchIcon
                                        color="secondary"
                                        className="mr-2"
                                    />
                                ),
                                endAdornment: (
                                    <IconButton
                                        type="button"
                                        onClick={actionOnClearSearch}
                                    >
                                        <ClearIcon
                                            className={
                                                searchValue
                                                    ? "secondary"
                                                    : "white"
                                            }
                                        />
                                    </IconButton>
                                ),
                            }}
                        />
                    </Box>
                    <Box className="mt-2">
                        {!activeGroupId && (
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={() => {
                                    if (totalRecord > 0) {
                                        loadAllUsersList();
                                    }
                                }}
                                disabled={totalRecord === 0}
                            >
                                <CSVLink
                                    {...csvReport}
                                    className="f14 cursor-pointer myriadpro-regular white"
                                >
                                    Export
                                </CSVLink>
                            </Button>
                        )}
                    </Box>
                </Box>

                <Table stickyHeader className="app-table mt-2">
                    <TableHead>
                        <TableRow className="primary-border-bottom ">
                            <TableCell>User ID</TableCell>
                            <TableCell>User Name</TableCell>
                            <TableCell>Email</TableCell>
                            {!activeGroupId && (
                                <TableCell>Group Name</TableCell>
                            )}
                            <TableCell>Role</TableCell>
                        </TableRow>
                    </TableHead>
                    {!loadingUsersList && (
                        <TableBody>
                            {usersList.length !== 0 &&
                                usersList.map((user) => (
                                    <TableRow key={user.userId}>
                                        <TableCell>
                                            <Typography className="user-email">
                                                {user.userId}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography className="user-email">
                                                {user.name ? user.name : "-"}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography className="user-email">
                                                {user.email}
                                            </Typography>
                                        </TableCell>
                                        {!activeGroupId && (
                                            <TableCell>
                                                <Typography className="user-email">
                                                    {user.groupName}
                                                </Typography>
                                            </TableCell>
                                        )}

                                        <TableCell>
                                            <Typography className="user-email">
                                                {getRoles(user.role)}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    )}
                </Table>
                <EmptyList
                    showLoader={loadingUsersList}
                    showEmpty={usersList.length === 0 && isUsersLoaded}
                    title="No Users Found"
                    subTitle=""
                />
                {usersList.length > 0 && !loadingUsersList && (
                    <TablePagination
                        rowsPerPageOptions={[2, 10, 25, 50]}
                        component="div"
                        count={paginations.total}
                        page={paginations.offset - 1}
                        onPageChange={actionHandleChangePage}
                        rowsPerPage={paginations.limit}
                        onRowsPerPageChange={actionHandleChangeRowsPerPage}
                    />
                )}
            </Box>
        </>
    );
};

export default UsersList;
