import { useState, forwardRef, useImperativeHandle } from "react";
import { Typography, Box, Dialog, CircularProgress } from "@material-ui/core";
import JSZip from "jszip";
import JSZipUtils from "jszip-utils";
import { toast } from "react-toastify";
import { saveAs } from "file-saver";
import moment from "moment";

// Modifies the modified and created date
const currDate = new Date();
const dateWithOffset = new Date(
    currDate.getTime() - currDate.getTimezoneOffset() * 60000
);
JSZip.defaults.date = dateWithOffset;

const SystemFileDownload = forwardRef((props, ref) => {
    useImperativeHandle(ref, () => ({
        startDownFile,
    }));
    const [openFormModal, setOpenformModal] = useState(false);
    const { onDownloadSuccess } = props;
    let fileNames = [];

    //Instance Methods
    const startDownFile = async (document) => {
        // downloadFiles({
        //     url: document.url,
        //     extension: document.extension,
        //     documentType: document.documentType,
        // });
        try {
            setOpenformModal(true);
            const dateTime = moment().format("DDMMMYYYY_hh_mma");
            if (document.documentType === "single") {
                saveAs(document.url, `${document.fileName}_${dateTime}.pdf`);
            } else if (document.documentType === "bulk") {
                const downloadedContent = await downloadMultipleFiles([
                    ...document.url,
                ]);
                saveAs(downloadedContent, `RedactedFiles_${dateTime}.zip`);
            }
            setOpenformModal(false);
        } catch (error) {
            console.log(error);
        }
    };

    const getNewFileHandle = async (downloadInfo) => {
        const mimeType =
            downloadInfo.extension === "zip"
                ? "application/zip"
                : "application/pdf";
        const options = {
            types: [
                {
                    description: "Redacted Files",
                    accept: {
                        [mimeType]: [`.${downloadInfo["extension"]}`],
                    },
                },
            ],
        };
        const handle = await window.showSaveFilePicker(options);
        return handle;
    };

    const writeFile = async (fileHandle, contents) => {
        // Create a FileSystemWritableFileStream to write to.
        const writable = await fileHandle.createWritable();
        // Write the contents of the file to the stream.
        await writable.write(contents);
        // Close the file and write the contents to disk.
        await writable.close();
    };

    const getFileName = (file) => {
        let name = file.fileName.split(".").slice(0, -1).join(".");
        let occurance = 0;
        fileNames.forEach((f) => f === name && occurance++);
        fileNames.push(name);
        name = occurance == 0 ? `${name}.pdf` : `${name}_(${occurance}).pdf`;
        return name;
    };

    const downloadMultipleFiles = (files) => {
        return new Promise(async (resolve, reject) => {
            var zip = new JSZip();
            try {
                for (let i = 0; i < files.length; i++) {
                    const file = files[i];
                    const fileContent = await JSZipUtils.getBinaryContent(
                        file.url
                    );
                    zip.file(getFileName(file), fileContent, {
                        binary: true,
                    });
                }
                zip.generateAsync({ type: "blob" }).then((content) => {
                    resolve(content);
                });
            } catch (error) {
                reject(error);
            }
        });
    };

    // UI Actions
    const downloadFiles = async (downloadInfo) => {
        try {
            let { documentType, url } = downloadInfo;
            const fileHandle = await getNewFileHandle(downloadInfo);
            setOpenformModal(true);
            let fileContents;
            if (documentType === "single") {
                fileContents = await JSZipUtils.getBinaryContent(url);
            } else if (documentType === "bulk") {
                fileContents = await downloadMultipleFiles(downloadInfo.url);
            }
            await writeFile(fileHandle, fileContents);
            setOpenformModal(false);
            toast.success("Download Completed");
            if (onDownloadSuccess) {
                onDownloadSuccess();
            }
        } catch (error) {
            if (
                error instanceof Object &&
                error.message &&
                error.message != "The user aborted a request."
            ) {
                toast.error("Oop's something went wrong. Please try again");
            }
        }
    };

    // UI Elements
    return (
        <Box>
            <Dialog maxSize="sm" open={openFormModal}>
                <Box display="flex" flexDirection="column" alignItems="center">
                    <Box p={2}>
                        <Typography className="f20 poppins-semi-bold">
                            Downloading...
                        </Typography>
                    </Box>
                    <Box p={2}>
                        <CircularProgress />
                    </Box>
                </Box>
            </Dialog>
        </Box>
    );
});

export default SystemFileDownload;
