import store from "../../state/rootReducer";
import { RestEnds } from "../constants";
import AppPreference from "./app-preference";
import { setLoginData, logoutUser } from "../../state/authentication";
import jwt_decode from "jwt-decode";
import { toast } from "react-toastify";

const axios = require("axios");
var instance = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
});

const logout = () => {
    store.dispatch(logoutUser({}));
    window.location.href = store.getState().authState.url.logout;
};

const isValidRole = (accessToken) => {
    const tokenData = jwt_decode(accessToken);
    const { role } = tokenData;
    const loginRole = store.getState().authState.role;
    return role === loginRole;
};

/**
 *  Used to modify the request headers common for all requests
 */
instance.interceptors.request.use((config) => {
    //Set request content type
    config.headers["Content-Type"] = "application/json";

    //Append auth header
    if (config.headers["append-auth-header"] == "true") {
        const accessToken =
            AppPreference.getAccessToken() ||
            store.getState().authState.accessToken;
        if (accessToken != "") {
            if (isValidRole(accessToken)) {
                config.headers["x-auth-token"] = accessToken;
            } else {
                logout();
            }
        }
    }
    return config;
});

//Intercept api response and fetch referesh toke 401
let isAlreadyFetchingAccessToken = false;
let subscribers = [];

const onAccessTokenFetched = (accessToken) => {
    subscribers = subscribers.filter((callback) => callback(accessToken));
};

const addSubscriber = (callback) => {
    subscribers.push(callback);
};

instance.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        if (error && error.response) {
            const {
                config,
                response: { status },
            } = error;
            const originalRequest = config;
            if (status === 401 && config.url != RestEnds.REFRESH_TOKEN) {
                if (!isAlreadyFetchingAccessToken) {
                    isAlreadyFetchingAccessToken = true;
                    refreshAuthLogic();
                }
                const retryOriginalRequest = new Promise((resolve) => {
                    addSubscriber((accessToken) => {
                        originalRequest.headers["x-auth-token"] = accessToken;
                        resolve(axios(originalRequest));
                    });
                });
                return retryOriginalRequest;
            }
            return Promise.reject(error);
        }
    }
);

// Function that will be called to refresh authorization
const refreshAuthLogic = () =>
    instance
        .post(
            RestEnds.REFRESH_TOKEN,
            {},
            {
                headers: {
                    "x-auth-token":
                        AppPreference.getRefreshToken() ||
                        store.getState().authState.refreshToken,
                },
            }
        )
        .then((response) => {
            const { accessToken, refreshToken } = response.data.data;
            store.dispatch(
                setLoginData({
                    refreshToken,
                    accessToken,
                })
            );
            isAlreadyFetchingAccessToken = false;
            onAccessTokenFetched(accessToken);
        })
        .catch((error) => {
            store.dispatch(logoutUser({}));
            const returnUrl = store.getState().authState.url.logout;
            window.location.href = returnUrl || "/welcome";
        });

export default instance;
