import { Box, CircularProgress } from "@material-ui/core";

const EmptyList = (props) => {
    let { showEmpty, showLoader, title, subTitle } = props;

    //UI Elements
    return (
        <>
            <Box
                className={`${
                    showLoader ? "empty-list empty-list-height" : "empty-list"
                }`}
            >
                {showEmpty && !showLoader && (
                    <>
                        <img src="/images/empty_note.png" />
                        <Box className="poppins-semi-bold f16 title">
                            {title}
                        </Box>
                        <Box className="lato-regular f14 description">
                            {subTitle}
                        </Box>
                    </>
                )}
                {showLoader && <CircularProgress />}
            </Box>
        </>
    );
};

export default EmptyList;
