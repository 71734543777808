import { Report } from "./components";
import ReportHistory from "./components/ReportHistory";
import { Paper, Tabs, Tab, Box } from "@material-ui/core";
import { useState } from "react";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { useNavigate } from "react-router-dom";

const ReportPage = () => {
    const navigate = useNavigate();
    const [activeTab, setActiveTab] = useState(1);
    const handleTabChange = (event, newValue) => {
        if (newValue === 0) {
            navigate(-1);
        } else {
            setActiveTab(newValue);
        }
    };

    // UI Elements
    return (
        <>
            <Paper square>
                <Tabs
                    value={activeTab}
                    indicatorColor="primary"
                    textColor="primary"
                    onChange={handleTabChange}
                >
                    <Tab
                        label={
                            <Box display="flex">
                                <ArrowBackIcon />
                                <Box ml={2}>Go Back</Box>
                            </Box>
                        }
                    />
                    <Tab label="Report" />
                    <Tab label="Redaction History" />
                </Tabs>
            </Paper>
            {activeTab === 1 && <Report />}
            {activeTab === 2 && <ReportHistory />}
        </>
    );
};

export default ReportPage;
