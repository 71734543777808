class FreshChat {
    static $inject = [];
    isMobile = false;
    userInfo = {
        email: "",
        firstName: "",
        lastName: "",
        phone: "",
    };

    constructor() {
        this.isMobile =
            /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                navigator.userAgent
            );
    }

    //init freshchat plugin
    initFreshChat() {
        //init if is not mobile
        if (!this.isMobile && window.fcWidget) {
            //check if need to be init frashchat
            if (!window.fcWidget.isInitialized()) {
                var restoreId = null;
                window.fcWidget.user.clear();
                window.fcWidget.init({
                    config: {
                        cssNames: {
                            widget: "custom_fc_frame",
                        },
                    },
                    token: "db352f75-611c-469c-9de3-02258df96c16",
                    host: "https://wchat.freshchat.com",
                    restoreId: restoreId ? restoreId : null,
                });

                window.fcWidget.setConfig({
                    hideFileUpload: true,
                });
            }
        }
    }

    // Set user email for freshdesk
    setFreshUser(userInfo) {
        if (userInfo) {
            this.userInfo = userInfo;
        }
        //Do if is not mobile
        if (!this.isMobile && window.fcWidget) {
            //check if is init
            this.initFreshChat();
            setTimeout(() => {
                window.fcWidget.setExternalId(this.userInfo.email);
                window.fcWidget.user.setProperties({
                    firstName: this.userInfo.name,
                    lastName: "",
                    email: this.userInfo.email,
                    phone: "",
                });
            }, 500);
        }
    }

    //destroy freshchat plugin
    destroyFreshChat() {
        window.fcWidget.user.clear();
        setTimeout(() => {
            window.fcWidget.destroy();
        });
    }
}

const freshChat = new FreshChat();
document.addEventListener("DOMContentLoaded", function (event) {
    freshChat.setFreshUser();
});

export default freshChat;
