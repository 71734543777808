import ApiManager from "../shared/managers/api-manager";
import AppPreference from "../shared/managers/app-preference";
import { RestEnds } from "../shared/constants";
import store from "../state/rootReducer";

const verifyAuthToken = async () => {
    return ApiManager.sendPost(RestEnds.VERIFY_TOKEN, {}, true);
};

const getAppUrls = async () => {
    return ApiManager.sendGet(RestEnds.APP_URLS, true);
};

const logout = async () => {
    return ApiManager.sendPost(RestEnds.LOGOUT, {}, true);
};

const refreshAccessToken = () =>
    ApiManager.getProvider().post(
        RestEnds.REFRESH_TOKEN,
        {},
        {
            headers: {
                "x-auth-token":
                    AppPreference.getRefreshToken() ||
                    store.getState().authState.refreshToken,
            },
        }
    );
export const AuthService = {
    verifyAuthToken,
    getAppUrls,
    logout,
    refreshAccessToken,
};
