import { createTheme } from "@material-ui/core/styles";

const theme = createTheme({
    rook: {
        fontFamily: "MyriadPro",
    },
    palette: {
        primary: {
            contrastText: "#ffffff",
            main: "#004165",
        },
        secondary: {
            contrastText: "#ffffff",
            main: "#0072ac",
        },
    },
    MuiLinearProgress: {
        colorPrimary: {
            backgroundColor: "#cdd8e1",
        },
    },
});

export default theme;
