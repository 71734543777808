import update from "immutability-helper";

const subscriptionState = {
    status: false,
    clientId: "",
    subscriptionStatus: "",
    createdAt: "",
    updatedAt: "",
    subscriptionId: "",
    planId: "",
    title: "",
    description: "",
    planType: "",
    planLogo: "",
    unit: "",
    expireDate: "",
    live: {
        clientKey: "",
        clientSecret: "",
        secretStatus: "",
        callLimit: 0,
    },
    stripeId: "",
    card: false,
    adminApprovalStatus: "",
    userId: "",
};

const SET_SUBSCRIPTION_DATA = "SET_SUBSCRIPTION_DATA";

export const setSubscriptionData = (payload) => ({
    type: SET_SUBSCRIPTION_DATA,
    payload,
});

const SubscriptionReducer = (state = subscriptionState, action) => {
    switch (action.type) {
        case SET_SUBSCRIPTION_DATA:
            return update(state, {
                $merge: {
                    ...action.payload,
                },
            });
        default:
            return state;
    }
};

export default SubscriptionReducer;
