import React, {
    useState,
    forwardRef,
    useEffect,
    useMemo,
    createContext,
    useImperativeHandle,
} from "react";
import { Box, Button, ButtonGroup } from "@material-ui/core";
import { connect } from "react-redux";
import {
    setPageNumber,
    setFileIndex,
    setDrawables,
    setEditorToolStyle,
} from "../../../../state/redactDocument";
import CircularProgress from "@material-ui/core/CircularProgress";
import { pdfjs } from "react-pdf";
import { Scrollbars } from "react-custom-scrollbars";
import { REDACT_PREVIEW_ELEMENT } from "./pdf-editor/editor-helper";
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";
import PDFPage, { PAGE_SCALE } from "./pdf-editor/pdf-page";
import { useDebouncedEffect } from "../../../../shared/hooks";
import { useContext } from "react";
import { DocumentPreviewContext } from "./document-preview";

pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;
export const PdfEditorContext = createContext({});

var PDFViewer = forwardRef((props, ref) => {
    const PAGE_WINDOW = 5;
    let { previewUrl, isPDF, onPDFLoad, onActivePageChanged } = props;
    const { zoom, setZoom } = useContext(DocumentPreviewContext);
    const [loadingPDF, setLoadingPDF] = useState(false);
    const [pdfPages, setPdfPages] = useState([]);
    const [pageCache, setPageCache] = useState({});
    const [editorSizes, setEditorSizes] = useState({
        0: {
            width: 0,
            height: 0,
            scale: PAGE_SCALE,
        },
    });
    const [activePageNumber, setActivePageNumber] = useState(0);
    const [isMovingPage, setIsMovingPage] = useState(false);

    useImperativeHandle(ref, () => ({
        editorSizes,
        focusPageNumber,
    }));

    useEffect(() => {
        if (previewUrl && isPDF) {
            loadPDF();
        }
    }, [previewUrl]);

    useDebouncedEffect(
        () => {
            onActivePageChanged(activePageNumber);
        },
        [activePageNumber],
        500
    );

    const loadPDF = async () => {
        setLoadingPDF(true);
        const pdfDoc = await pdfjs.getDocument(previewUrl).promise;
        const pages = [];
        for (let i = 0; i < pdfDoc.numPages; i++) {
            const page = pdfDoc.getPage(i + 1);
            pages.push(page);
        }
        setPdfPages(pages);
        setLoadingPDF(false);
        onPDFLoad(pages);
    };

    const canAlignCenter = () => {
        const previewElement = document.getElementsByClassName(
            REDACT_PREVIEW_ELEMENT
        );
        const editorSize = editorSizes[activePageNumber];
        if (previewElement.length > 0 && editorSize) {
            return editorSize.width < previewElement[0].offsetWidth;
        }
        return true;
    };

    const editorPreviewHeight = useMemo(() => {
        const previewElement = document.getElementsByClassName(
            REDACT_PREVIEW_ELEMENT
        );
        if (previewElement.length > 0) {
            return previewElement[0].offsetHeight;
        }
        return 0;
    }, [pdfPages]);

    const canRenderPage = useMemo(
        () => (pageNumber) => {
            const windowStart = activePageNumber - PAGE_WINDOW;
            const windowEnd = activePageNumber + PAGE_WINDOW;
            return windowStart <= pageNumber && pageNumber <= windowEnd;
        },
        [activePageNumber]
    );

    const pageWindows = useMemo(() => {
        const windowStart = activePageNumber - PAGE_WINDOW;
        const windowEnd = activePageNumber + PAGE_WINDOW;
        const start = windowStart < 0 ? 0 : windowStart;
        const end = windowEnd >= pdfPages.length ? pdfPages.length : windowEnd;
        const windows = Array.from(
            { length: end - start },
            (_, i) => i + start
        );
        return windows;
    }, [activePageNumber, pdfPages]);

    const focusPageNumber = (page) => {
        setIsMovingPage(true);
        setActivePageNumber(page - 1);
        setTimeout(() => {
            const element = document.getElementById(`pdf-page${page - 1}`);
            if (element) {
                element.scrollIntoView({
                    behavior: "smooth",
                });
            }
        }, 1000);
    };

    return (
        <>
            <Box
                className="empty-list pdf-loading-container"
                style={{
                    display: loadingPDF ? "flex" : "none",
                }}
            >
                <CircularProgress></CircularProgress>
            </Box>

            <PdfEditorContext.Provider
                value={{
                    zoom,
                    setZoom,
                    editorSizes,
                    setEditorSizes,
                    activePageNumber,
                    setActivePageNumber,
                    isMovingPage,
                    setIsMovingPage,
                    pageCache,
                    setPageCache,
                }}
            >
                <Scrollbars autoHide className="scrollbar-container">
                    <Box
                        className="document-preview"
                        style={{
                            alignItems: canAlignCenter()
                                ? "center"
                                : "flex-start",
                            height: editorPreviewHeight + "px",
                        }}
                    >
                        {pageWindows.map((index) => {
                            return (
                                <PDFPage
                                    key={`pdf_${index}`}
                                    page={pdfPages[index]}
                                    pageNumber={index}
                                ></PDFPage>
                            );
                        })}
                    </Box>
                </Scrollbars>
            </PdfEditorContext.Provider>
        </>
    );
});

const mapStateToProps = (state) => {
    return {
        redactPageNumber: state.redactDocumentState.pageNumber,
        redactFileIndex: state.redactDocumentState.fileIndex,
        redactDrawables: state.redactDocumentState.drawables,
        editorStyle: state.redactDocumentState.editorTool,
        canEdit: state.redactDocumentState.canDraw,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setRedactPageNumber: (data) => dispatch(setPageNumber(data)),
        setRedactFileIndex: (data) => dispatch(setFileIndex(data)),
        setRedactDrawables: (data) => dispatch(setDrawables(data)),
        setEditorStyle: (data) => dispatch(setEditorToolStyle(data)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps, null, {
    forwardRef: true,
})(PDFViewer);
