import * as React from "react";
import { useNavigate } from "react-router-dom";
import {
    Card,
    Avatar,
    Container,
    Paper,
    Typography,
    Grid,
    Box,
    Button,
} from "@material-ui/core";

const WelcomePage = () => {
    const navigate = useNavigate();

    // UI Actions
    const actionOnNavigate = (type) => {
        let token = "";

        if (type === "admin") {
            token = process.env.REACT_APP_SRT_ADMIN_ACCESS_TOKEN;
            navigate(`../auth?token=${token}`);
        } else if (type === "user") {
            token = process.env.REACT_APP_SRT_USER_ACCESS_TOKEN;
            navigate(`../auth?token=${token}`);
        }
    };

    // UI Elements
    return (
        <Box className="app-wrapper">
            <Container>
                <Box p={3} className="welcome-header">
                    <img src={"/images/anz-logo.png"} width="100" alt="" />
                </Box>
                {false && (
                    <Grid container className="justify-content" spacing={4}>
                        <Grid item xs={12} sm={3} lg={3}>
                            <Paper>
                                <Card className="welcome-card-container">
                                    <Box py={1} className="justify-center">
                                        <Avatar
                                            src="/static/images/avatar/1.jpg"
                                            width="100"
                                        />
                                    </Box>
                                    <Box py={1} className="justify-center">
                                        <Typography align="center" variant="h6">
                                            Login as user
                                        </Typography>
                                    </Box>

                                    <Box py={2}>
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            fullWidth
                                            className="myriadpro-regular"
                                            onClick={() =>
                                                actionOnNavigate("user")
                                            }
                                        >
                                            Login
                                        </Button>
                                    </Box>
                                </Card>
                            </Paper>
                        </Grid>
                        <Grid item xs={12} sm={3} lg={3}>
                            <Paper>
                                <Card className="welcome-card-container">
                                    <Box py={1} className="justify-center">
                                        <Avatar
                                            src="/static/images/avatar/1.jpg"
                                            width="100"
                                        />
                                    </Box>
                                    <Box py={1} className="justify-center">
                                        <Typography align="center" variant="h6">
                                            Login as admin
                                        </Typography>
                                    </Box>

                                    <Box py={2}>
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            fullWidth
                                            className="myriadpro-regular"
                                            onClick={() =>
                                                actionOnNavigate("admin")
                                            }
                                        >
                                            Login
                                        </Button>
                                    </Box>
                                </Card>
                            </Paper>{" "}
                        </Grid>
                    </Grid>
                )}
            </Container>
        </Box>
    );
};

export default WelcomePage;
