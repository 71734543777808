import { useEffect, useState } from "react";
import {
    Box,
    Card,
    TablePagination,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    // Container,
    Button,
    InputLabel,
    MenuItem,
    Select,
    FormControl,
    Checkbox,
    ListItemText,
} from "@material-ui/core";
import moment from "moment";
import "moment-timezone";
import { addDays, subDays } from "date-fns";
import EmptyList from "../../../../shared/components/EmptyList";
import { Utils } from "../../../../shared/helpers";
import {
    YesNoChip,
    AppDatePicker,
} from "../../../../shared/components/AppComponents";
import {
    DefaultPagination,
    AccessManagementService,
    ReportService,
} from "../../../../services";
import { CSVLink } from "react-csv";

const ReportHistory = () => {
    const [groups, setGroups] = useState([]);
    const [groupIds, setGroupIds] = useState([]);
    const [detailedReports, setDetailedReports] = useState([]);
    const [reports, setReports] = useState([]);
    const [pagination, setPagination] = useState({ ...DefaultPagination(10) });
    const [loadingReports, setLoadingReports] = useState(false);
    const defaultFromDate = subDays(new Date(), 7);
    const [fromDate, setFromDate] = useState(defaultFromDate);
    const [toDate, setToDate] = useState(new Date());
    const fromdate = moment(fromDate).format("MM-DD-YYYY");
    const todate = moment(toDate).format("MM-DD-YYYY");
    const [exportingReport, setExportingReport] = useState(false);

    const headers = [
        { label: "User ID", key: "userId" },
        { label: "Redaction ID", key: "documentId" },
        { label: "Date of redaction", key: "redactionDate" },
        { label: "Number of pages", key: "pagesCount" },
        { label: "First redaction selection", key: "firstRedaction" },
        { label: "Templates", key: "templateKeywords" },
        { label: "Keywords", key: "customKeywordRedactionUsed" },
        { label: "Draw Tool", key: "drawToolUsed" },
        { label: "Redaction success", key: "redactionSuccess" },
        { label: "Process Status", key: "processStatus" },
        { label: "File Status", key: "fileStatus" },
        {
            label: `Report for the period (From : ${moment(fromDate).format(
                "DD-MM-YYYY"
            )} To :  ${moment(toDate).format("DD-MM-YYYY")})`,
            key: "reportDate",
        },
    ];

    // Hooks
    useEffect(() => {
        loadDetailedReportHistory();
        loadGroups();
    }, []);

    useEffect(() => {
        resetPagination();
    }, [fromDate, toDate, groupIds]);

    const loadGroups = () => {
        AccessManagementService.allGroups()
            .then((response) => {
                let { groupList } = response["data"]["data"];
                setGroups(groupList);
            })
            .catch((error) => {});
    };

    const loadDetailedReportHistory = () => {
        setLoadingReports(true);
        const selectedGroupIds = groupIds.map((s) => s.groupId);
        ReportService.getDetailedHistory(
            pagination.limit,
            pagination.offset,
            fromdate,
            todate,
            selectedGroupIds.toString()
        )
            .then((response) => {
                const newPagination = response["data"]["data"]["pagination"];
                const reportResponse =
                    response["data"]["data"]["historyDetails"];
                setPagination(newPagination);
                setDetailedReports(reportResponse);
                setLoadingReports(false);
                loadAllReports(newPagination.totalCount);
            })
            .catch(() => {
                setLoadingReports(false);
            });
    };

    const loadAllReports = (totalCount) => {
        const exportSize = 10000;
        const totalPages = totalCount / exportSize;
        const promises = [];
        const selectedGroupIds = groupIds.map((s) => s.groupId);
        for (let i = 0; i < totalPages; i++) {
            const promise = ReportService.getDetailedHistory(
                exportSize,
                i + 1,
                fromdate,
                todate,
                selectedGroupIds
            );
            promises.push(promise);
        }
        setExportingReport(true);
        Promise.all(promises)
            .then((responses) => {
                const reportList = [];
                for (let i = 0; i < responses.length; i++) {
                    const response = responses[i];
                    const reportResponse =
                        response["data"]["data"]["historyDetails"];
                    reportResponse.forEach((report) => {
                        reportList.push({
                            userId: report.userEmailId,
                            documentId: report.documentId,
                            redactionDate: `${moment(
                                report.redactionDate
                            ).format("DD/MM/YYYY hh:mm A")}`,
                            pagesCount: report.pagesCount,
                            firstRedaction:
                                report.firstRedaction === false ? "No" : "Yes",
                            templateKeywords:
                                report.templateKeywords.length === 0
                                    ? "-"
                                    : report.templateKeywords,
                            customKeywordRedactionUsed:
                                report.customKeywordRedactionUsed === false
                                    ? "No"
                                    : "Yes",
                            drawToolUsed:
                                report.drawToolUsed === false ? "No" : "Yes",
                            redactionSuccess:
                                report.redactionSuccess === false
                                    ? "No"
                                    : "Yes",
                            processStatus: Utils.titleCase(
                                report.processStatus
                            ),
                            fileStatus: Utils.titleCase(report.fileStatus),
                        });
                    });
                }
                setReports(reportList);
                setExportingReport(false);
            })
            .catch(() => {
                setReports([]);
                setExportingReport(false);
            });
    };

    // UI Actions
    const actionHandleChangePage = (event, newPage) => {
        pagination.offset = newPage + 1;
        loadDetailedReportHistory();
    };

    const actionHandleChangeRowsPerPage = (event) => {
        pagination.offset = 1;
        pagination.limit = parseInt(event.target.value, 10);
        loadDetailedReportHistory();
    };

    // Instance Methods
    const resetPagination = () => {
        pagination.offset = 1;
        pagination.lastOffset = 0;
        loadDetailedReportHistory();
    };

    // UI Elements
    const getProcessStatus = (status) => {
        if (status === "completed") {
            return <TableCell className="main-primary">Completed</TableCell>;
        } else if (status === "error") {
            return <TableCell className="ruby">Error</TableCell>;
        } else if (status === "failed") {
            return <TableCell className="ruby">Failed</TableCell>;
        } else if (status === "re-redacted") {
            return <TableCell className="ruby">Re-Redacted</TableCell>;
        } else if (status === "unsuccessfull") {
            return <TableCell className="ruby">Unsuccessfull</TableCell>;
        } else if (status === "converting") {
            return <TableCell className="pale-yellow">Converting</TableCell>;
        } else if (status === "uploaded") {
            return <TableCell className="pale-yellow">Uploaded</TableCell>;
        } else if (status === "yet to process" || status === "processing") {
            return <TableCell className="pale-yellow">Processing</TableCell>;
        } else if (status === "deleted") {
            return <TableCell className="ruby">Deleted</TableCell>;
        } else if (status === "downloaded") {
            return <TableCell className="main-primary">Downloaded</TableCell>;
        }
    };

    // UI
    return (
        <Box pl={4} pr={4}>
            <Box className="justify-end" pt={3}>
                <Box className="group-box mr-2">
                    <InputLabel
                        id="demo-multiple-name-label"
                        className="f14 myriadpro-regular text-center mr-2"
                    >
                        Groups
                    </InputLabel>
                    <FormControl variant="outlined" size="small">
                        <InputLabel>
                            {groupIds.length == 0 ? "Select Group" : undefined}
                        </InputLabel>
                        <Select
                            label={
                                groupIds.length == 0
                                    ? "Select Group"
                                    : undefined
                            }
                            className="group-selection"
                            multiple={true}
                            value={groupIds}
                            onChange={(e) => {
                                setGroupIds(e.target.value);
                            }}
                            renderValue={(selected) => {
                                const selectedNames = selected.map(
                                    (s) => s.name
                                );
                                return selectedNames.toString();
                            }}
                        >
                            <MenuItem disabled>Select Group</MenuItem>
                            <MenuItem>
                                <Box
                                    className="select-all"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        if (groupIds.length == groups.length) {
                                            setGroupIds([]);
                                        } else {
                                            setGroupIds(groups);
                                        }
                                    }}
                                >
                                    <Checkbox
                                        checked={
                                            groupIds.length == groups.length
                                        }
                                    />
                                    <ListItemText primary="Select All" />
                                </Box>
                            </MenuItem>
                            {groups.map((group) => (
                                <MenuItem value={group}>
                                    <Checkbox
                                        checked={groupIds.some(
                                            (g) => g.groupId === group.groupId
                                        )}
                                    />
                                    <ListItemText primary={group.name} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box>

                <Box>
                    <AppDatePicker
                        size="small"
                        label="From Date"
                        placeholder="From Date"
                        name="fromDate"
                        value={fromDate}
                        onChange={(date) => {
                            setFromDate(date);
                        }}
                        maxDate={subDays(new Date(), 0)}
                    ></AppDatePicker>
                </Box>

                <Box>
                    <AppDatePicker
                        size="small"
                        label="To Date"
                        placeholder="To Date"
                        name="toDate"
                        value={toDate}
                        onChange={(date) => {
                            setToDate(date);
                        }}
                        minDate={fromDate}
                        maxDate={addDays(new Date(), 0)}
                    ></AppDatePicker>
                </Box>

                <Button
                    variant="contained"
                    color="secondary"
                    disabled={exportingReport || loadingReports}
                    style={{
                        opacity: exportingReport || loadingReports ? 0.7 : 1,
                    }}
                >
                    <CSVLink
                        data={reports}
                        headers={headers}
                        filename="ANZ_Redaction_Report.csv"
                        className="f14 cursor-pointer myriadpro-regular white"
                    >
                        Export
                    </CSVLink>
                </Button>
            </Box>
            <Box mt={2} paddingBottom={5}>
                <Card className="mb-40">
                    <Box
                        className={
                            detailedReports.length > 0 ? "overflow-scroll" : ""
                        }
                    >
                        <Table
                            stickyHeader
                            className={
                                detailedReports.length > 0
                                    ? "app-table user-report-table"
                                    : "app-table"
                            }
                        >
                            <TableHead>
                                <TableRow className="primary-border-bottom ">
                                    <TableCell>User ID</TableCell>
                                    <TableCell>Redaction ID</TableCell>
                                    <TableCell>Date of Redaction</TableCell>
                                    <TableCell>Page Count</TableCell>
                                    <TableCell>First Redaction</TableCell>
                                    <TableCell>Templates</TableCell>
                                    <TableCell>Keywords</TableCell>
                                    <TableCell>Draw Tool</TableCell>
                                    <TableCell>Redaction Success</TableCell>
                                    <TableCell>Process Status</TableCell>
                                    <TableCell>File Status</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {detailedReports.map((history, index) => {
                                    return (
                                        <TableRow key={index}>
                                            <TableCell className="text-word-break">
                                                {history.userEmailId}
                                            </TableCell>
                                            <TableCell className="text-word-break">
                                                {history.documentId}
                                            </TableCell>
                                            <TableCell>
                                                {moment(
                                                    history.redactionDate
                                                ).format("DD/MM/YYYY hh:mm A")}
                                            </TableCell>
                                            <TableCell>
                                                {history.pagesCount}
                                            </TableCell>
                                            <TableCell>
                                                <YesNoChip
                                                    flag={
                                                        history.firstRedaction ===
                                                        true
                                                            ? "Yes"
                                                            : "No"
                                                    }
                                                />
                                            </TableCell>
                                            <TableCell className="text-word-break">
                                                {history.templateKeywords
                                                    .length > 0
                                                    ? history.templateKeywords.toString()
                                                    : "-"}
                                            </TableCell>
                                            <TableCell>
                                                <YesNoChip
                                                    flag={
                                                        history.customKeywordRedactionUsed ===
                                                        true
                                                            ? "Yes"
                                                            : "No"
                                                    }
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <YesNoChip
                                                    flag={
                                                        history.drawToolUsed ===
                                                        true
                                                            ? "Yes"
                                                            : "No"
                                                    }
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <YesNoChip
                                                    flag={
                                                        history.redactionSuccess ===
                                                        true
                                                            ? "Yes"
                                                            : "No"
                                                    }
                                                />
                                            </TableCell>
                                            {getProcessStatus(
                                                history.processStatus
                                            )}
                                            <TableCell>
                                                {history.fileStatus}
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>{" "}
                    </Box>
                    {detailedReports.length > 0 && (
                        <TablePagination
                            rowsPerPageOptions={[2, 10, 25, 50]}
                            component="div"
                            count={pagination.totalCount}
                            page={pagination.offset - 1}
                            onChangePage={actionHandleChangePage}
                            rowsPerPage={pagination.limit}
                            onChangeRowsPerPage={actionHandleChangeRowsPerPage}
                        />
                    )}
                    {detailedReports.length === 0 && (
                        <EmptyList
                            showLoader={loadingReports}
                            showEmpty={detailedReports.length === 0}
                            title="No reports recorded yet"
                            subTitle=""
                        />
                    )}
                </Card>
            </Box>
        </Box>
    );
};

export default ReportHistory;
