import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { useRoutes } from "react-router-dom";
import routesElements from "./routes";
import { ThemeProvider } from "@material-ui/core/styles";
import theme from "./assets/scss/theme";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";
import "./assets/scss/app.scss";
// import { FreshChat } from "./shared/helpers";
import AppContext from "./contexts/app-context";

const App = () => {
    const [validFiles, setValidFiles] = useState([]);
    const [isUploading, setIsUploading] = useState(false);

    const location = useLocation();
    const isUserLoggedIn = useSelector(
        (state) => state.authState.isUserLoggedIn
    );
    const authState = useSelector((state) => state.authState);

    useEffect(() => {
        if (isUserLoggedIn) {
            // FreshChat.setFreshUser(authState);
        }
    }, [isUserLoggedIn]);

    const routes = useRoutes(routesElements(isUserLoggedIn, location.pathname));
    return (
        <ThemeProvider theme={theme}>
            <AppContext.Provider
                value={{
                    validFiles,
                    setValidFiles,
                    isUploading,
                    setIsUploading,
                }}
            >
                <React.Fragment>{routes}</React.Fragment>
                <ToastContainer autoClose={5000} closeOnClick />
            </AppContext.Provider>
        </ThemeProvider>
    );
};

export default App;
