export const RestEnds = {
    //Redact
    REDACT_DOCUMENT: (timeZone) => `/v1/redact?timezone=${timeZone}`,
    REDACT_HISTORY: "/v1/history",
    BULK_DOWNLOAD: (allReviewed, timeZone) =>
        `/v1/bulk-download/${allReviewed}?timezone=${timeZone}`,
    REVIEW_DOCUMENT: "/v1/review",
    BULK_DOWNLOAD_SELECTED: (selectedIds, timeZone) =>
        `/v1/download?ids=${selectedIds}&timezone=${timeZone}`,
    UPLOAD: "/v1/upload",
    DELETE_HISTORY: "/v1/delete",

    // AccessManagement
    POLICY_HISTORY: "/v1/users",
    UPDATE_POLICY: "/v1/policy",
    TEMPLATE_LIST: "v1/templates",
    POLICY_CONFIG: "/v1/policy",
    GROUP_POLICY_HISTORY: "v1/groups/policy",
    ALL_GROUPS: "v1/groups",
    UPDATE_GROUP_NAME: "/v1/groups",
    GROUP_USERS_HISTORY: "v1/users",

    GET_USERS: (offset, limit, query, groupId) =>
        `/v1/users?offset=${offset}&limit=${limit}&query=${query}&groupId=${groupId}`,

    // Report
    REPORT: "/v1/report",
    REPORT_DETAILED_HISTORY: "/v1/detailed-history",

    // App Urls
    APP_URLS: "/v1/url",

    // Authenticate
    VERIFY_TOKEN: "/v1/auth",
    LOGOUT: "/v1/logout",
    REFRESH_TOKEN: "/v1/token",

    // Websocket
    WEBSOCKET_URL: "/v1/negotiate",
};
