import React from "react";
import { NavLink, useNavigate } from "react-router-dom";
import {
    AppBar,
    Avatar,
    Box,
    makeStyles,
    Toolbar,
    IconButton,
    Typography,
    MenuItem,
    Menu,
    Popper,
    Grow,
    Paper,
    ClickAwayListener,
    MenuList,
} from "@material-ui/core";
import { connect } from "react-redux";
import AccountCircle from "@material-ui/icons/AccountCircle";
import MoreIcon from "@material-ui/icons/MoreVert";
import { logoutUser } from "../../state/authentication";
import { AuthService } from "../../services";
import { Utils } from "../../shared/helpers";

const useStyles = makeStyles((theme) => ({
    appBar: {
        zIndex: 1400,
    },
    drawer: {
        top: 67,
    },
    mobileMenu: {
        width: 300,
    },
    grow: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(0),
    },
    title: {
        display: "none",
        [theme.breakpoints.up("sm")]: {
            display: "block",
        },
    },
    sectionDesktop: {
        height: "100%",
        display: "none",
        [theme.breakpoints.up("md")]: {
            display: "flex",
        },
    },
    sectionMobile: {
        display: "flex",
        [theme.breakpoints.up("md")]: {
            display: "none",
        },
    },
    blackColor: {
        color: "black",
        width: "100%",
    },
    nested: {
        paddingLeft: theme.spacing(50),
    },
    slimFont: {
        fontWeight: 300,
        fontSize: 20,
    },
}));

const AppHeader = (props) => {
    const { authState, logoutUser } = props;
    const classes = useStyles();
    const navigate = useNavigate();

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

    const isMenuOpen = Boolean(anchorEl);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

    const handleProfileMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        handleMobileMenuClose();
    };

    const logout = () => {
        handleMobileMenuClose();
        logoutUser();
        window.location.href = authState.url.logout;
    };

    const actionOnLogout = () => {
        setAnchorEl(null);
        AuthService.logout()
            .then((response) => {
                logout();
            })
            .catch((error) => {
                logout();
            });
    };

    const handleMobileMenuOpen = (event) => {
        setMobileMoreAnchorEl(event.currentTarget);
    };

    const menuId = "primary-search-account-menu";
    const renderMenu = (
        <>
            <Popper
                open={isMenuOpen}
                anchorEl={anchorEl}
                transition
                disablePortal
            >
                {({ TransitionProps, placement }) => (
                    <Grow {...TransitionProps}>
                        <Paper>
                            <ClickAwayListener onClickAway={handleMenuClose}>
                                <MenuList
                                    autoFocusItem={isMenuOpen}
                                    onKeyDown={handleMenuClose}
                                    aria-label="User Menu List"
                                >
                                    {authState.role === "admin" && (
                                        <>
                                            <MenuItem
                                                component={NavLink}
                                                to={"/report"}
                                                onClick={handleMenuClose}
                                                aria-label="Report"
                                            >
                                                Report
                                            </MenuItem>
                                            <MenuItem
                                                component={NavLink}
                                                to={"/users"}
                                                onClick={handleMenuClose}
                                                aria-label="Users"
                                            >
                                                Users
                                            </MenuItem>

                                            <MenuItem
                                                component={NavLink}
                                                to={"/access-management"}
                                                onClick={handleMenuClose}
                                                aria-label="Groups"
                                            >
                                                Groups
                                            </MenuItem>
                                        </>
                                    )}
                                    <MenuItem
                                        onClick={actionOnLogout}
                                        aria-label="Logout"
                                    >
                                        Logout
                                    </MenuItem>
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </>
    );

    const mobileMenuId = "primary-search-account-menu-mobile";
    const renderMobileMenu = (
        <Popper
            open={isMobileMenuOpen}
            anchorEl={mobileMoreAnchorEl}
            transition
            disablePortal
        >
            {({ TransitionProps, placement }) => (
                <Grow {...TransitionProps}>
                    <Paper>
                        <ClickAwayListener onClickAway={handleMobileMenuClose}>
                            <MenuList
                                autoFocusItem={isMenuOpen}
                                onKeyDown={handleMobileMenuClose}
                                aria-label="User Menu List"
                            >
                                <MenuItem>
                                    <a
                                        href={`${authState.url.apps}`}
                                        rel="noreferrer"
                                    >
                                        <Typography className="f16 black">
                                            Status Board
                                        </Typography>
                                    </a>
                                </MenuItem>

                                {authState.role === "admin" && (
                                    <>
                                        <MenuItem
                                            component={NavLink}
                                            to={"/report"}
                                            onClick={handleMobileMenuClose}
                                            aria-label="Report"
                                        >
                                            Report
                                        </MenuItem>
                                        <MenuItem
                                            component={NavLink}
                                            to={"/users"}
                                            onClick={handleMobileMenuClose}
                                            aria-label="Users"
                                        >
                                            Users
                                        </MenuItem>

                                        <MenuItem
                                            component={NavLink}
                                            to={"/access-management"}
                                            onClick={handleMobileMenuClose}
                                            aria-label="Groups"
                                        >
                                            Groups
                                        </MenuItem>
                                    </>
                                )}
                                <MenuItem
                                    onClick={actionOnLogout}
                                    aria-label="Logout"
                                >
                                    Logout
                                </MenuItem>
                            </MenuList>
                        </ClickAwayListener>
                    </Paper>
                </Grow>
            )}
        </Popper>
    );
    return (
        <div className={classes.grow}>
            <AppBar
                role="banner"
                position="static"
                className="app-header-container"
            >
                <Toolbar>
                    <Box
                        className="align-center cursor-pointer"
                        onClick={() => navigate("/redact")}
                    >
                        <img
                            className={classes.menuButton}
                            src={"/images/anz-logo.png"}
                            width="100"
                            alt="ANZ Redact Logo"
                        />

                        <Typography className={classes.slimFont}>
                            Redact
                        </Typography>
                    </Box>
                    <div className={classes.grow} />
                    <Box className={classes.sectionDesktop}>
                        <a
                            className="align-center"
                            href={`${authState.url.apps}`}
                            rel="noreferrer"
                            aria-label="Status Board Button"
                        >
                            <Avatar src={"/images/icons/icon-app.png"} />
                            <Box ml={1}>
                                <Typography className="white f16 myriadpro-semi-bold">
                                    Status Board
                                </Typography>
                            </Box>
                        </a>
                        <IconButton
                            aria-label={`User login menu ${
                                isMenuOpen ? "expanded" : "collapsed"
                            }`}
                            color="inherit"
                            onClick={handleProfileMenuOpen}
                            className="text-initial"
                        >
                            <Avatar
                                src={
                                    authState.imageUrl
                                        ? authState.imageUrl
                                        : "/images/icons/icon-user.png"
                                }
                            />
                            <Box ml={1}>
                                <Typography className="f16 myriadpro-semi-bold ">
                                    {authState.name}
                                </Typography>
                            </Box>
                        </IconButton>
                    </Box>
                    <div className={classes.sectionMobile}>
                        <IconButton
                            aria-label="show 17 new notifications"
                            color="inherit"
                            aria-controls={mobileMenuId}
                            onClick={handleMobileMenuOpen}
                        >
                            <Avatar
                                src={
                                    authState.imageUrl
                                        ? authState.imageUrl
                                        : "/images/icons/icon-user.png"
                                }
                            />
                            <Box ml={1}>
                                <Typography className="f16 myriadpro-semi-bold">
                                    {authState.name}
                                </Typography>
                            </Box>
                        </IconButton>
                    </div>
                </Toolbar>
            </AppBar>

            {renderMobileMenu}
            {renderMenu}
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        authState: state.authState,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        logoutUser: () => dispatch(logoutUser()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AppHeader);
