import {
    Box,
    Button,
    Typography,
    Tooltip,
    IconButton,
} from "@material-ui/core";
import { useState, createRef, useEffect, useContext } from "react";
import Dropzone from "react-dropzone";
import LinearProgress from "@material-ui/core/LinearProgress";
import React from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { Document, pdfjs } from "react-pdf";
import { AuthService, RedactionService } from "../../../../services";
import reactImageSize from "react-image-size";
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";
import { Utils } from "../../../../shared/helpers";
import moment from "moment";
import { APIManager } from "../../../../shared/managers";
import { setLoginData } from "../../../../state/authentication";
import AppContext from "../../../../contexts/app-context";

pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;
Document.defaultProps = {
    error: "",
    loading: "",
    noData: "",
};

const DocumentUpload = (props) => {
    const { onNewFileUploaded, setLoginData } = props;
    const maxFiles = 10;
    const maxPageCount = 1000;
    const maxPDFFileSize = 150; //MB
    const maxImageFileSize = 10; //MB
    const dropzoneRef = createRef();
    const { validFiles, setValidFiles, isUploading, setIsUploading } =
        useContext(AppContext);
    const [preValidationFinished, setPreValidationFinished] = useState(false);
    const minImageWidth = 600;
    const minImageHeight = 600;

    useEffect(() => {
        if (preValidationFinished && validFiles.length > 0) {
            uploadValidatedFiles();
        }
    }, [preValidationFinished]);

    //Instance Methods
    const isImageFile = (file) => {
        return (
            file.type != "application/pdf" &&
            file.type !=
                "application/vnd.openxmlformats-officedocument.wordprocessingml.document" &&
            file.type != "application/msword"
        );
    };

    const validateFileSizes = (files) => {
        const smallFiles = [];
        files.forEach((file) => {
            const fileSize = file.size / 1024 / 1024;
            const fileSizeToCheck = isImageFile(file)
                ? maxImageFileSize
                : maxPDFFileSize;
            if (fileSize <= fileSizeToCheck) {
                smallFiles.push(file);
            }
        });
        if (files.length !== smallFiles.length) {
            toast.info(
                `File size should be less than ${maxPDFFileSize}MB for PDF and ${maxImageFileSize}MB for images`
            );
        }
        return smallFiles;
    };

    const validatePasswordProtected = async (files) => {
        const tempFiles = [];
        let exception = "";
        let errorDisplayed = "";
        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            if (file.type == "application/pdf") {
                try {
                    const fileUrl = URL.createObjectURL(file);
                    const pdfDoc = await pdfjs.getDocument(fileUrl).promise;
                    if (pdfDoc.numPages <= maxPageCount) {
                        tempFiles.push(file);
                    } else {
                        toast.info(`Max page count can be ${maxPageCount}`);
                    }
                } catch (error) {
                    if (error instanceof Object && error["name"]) {
                        exception = error.name;
                    }
                    toast.info(
                        "The document you have selected is corrupted or protected with a password"
                    );
                }
            } else {
                tempFiles.push(file);
            }
        }
        return tempFiles;
    };

    const validateImageDimension = async (files) => {
        const tempFiles = [];
        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            if (!isImageFile(file)) {
                tempFiles.push(file);
            } else {
                const fileUrl = URL.createObjectURL(file);
                const { width, height } = await reactImageSize(fileUrl, 10000);
                if (width >= minImageWidth && height >= minImageHeight) {
                    tempFiles.push(file);
                }
            }
        }
        if (files.length !== tempFiles.length) {
            toast.info(`Image should be above 600 x 600 pixels`);
        }
        return tempFiles;
    };

    const validateDuplicateFiles = (files) => {
        const uniqueFiles = [];
        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            const fileIndex = validFiles.findIndex((oldFile) => {
                return oldFile.name === file.name;
            });
            if (fileIndex === -1) {
                uniqueFiles.push(file);
            }
        }
        if (files.length !== uniqueFiles.length) {
            toast.error(`Duplicate files not allowed`);
        }
        return uniqueFiles;
    };

    const appendFileProgress = (files) => {
        files.map((file) => {
            return Object.assign(file, {
                progressPercentage: 0,
            });
        });
        return files;
    };

    //UI Actions
    const actionSelectFile = () => {
        if (dropzoneRef.current) {
            dropzoneRef.current.open();
        }
    };

    const actionUpload = async () => {
        try {
            AuthService.refreshAccessToken().then((response) => {
                const { accessToken, refreshToken } = response.data.data;
                setLoginData({
                    refreshToken,
                    accessToken,
                });
                preValidateFiles();
            });
        } catch (error) {
            toast.info("We are unable to process this file");
        }
    };

    const actionCancelUpload = (index) => {
        if (index < validFiles.length) {
            const file = validFiles[index];
            if (isUploading && file["cancelSource"]) {
                file["cancelSource"].cancel();
            }
            validFiles.splice(index, 1);
            setValidFiles([...validFiles]);
        }
    };

    const preValidateFiles = async () => {
        const validFileDimensions = await validateImageDimension(validFiles);
        const allValidFiles = await validatePasswordProtected(
            validFileDimensions
        );
        setValidFiles(allValidFiles);
        setPreValidationFinished(true);
    };

    const uploadValidatedFiles = async () => {
        for (let i = 0; i < validFiles.length; i++) {
            const file = validFiles[i];
            uploadFiles(file, i);
        }
    };

    const prepareDataToUpload = async (file, index) => {
        const uniqueId = moment().unix() + index;
        const cancelSource = APIManager.getCancelSource();
        const config = {
            onUploadProgress: (event) => {
                const progress = Math.round((100 * event.loaded) / event.total);
                updateProgress(uniqueId, progress);
            },
            cancelToken: cancelSource.token,
        };

        const formData = new FormData();
        formData.append("file", file);
        formData.append("docId", uniqueId);
        let progressInfo = [...validFiles];
        progressInfo[index]["fileRef"] = uniqueId;
        progressInfo[index]["cancelSource"] = cancelSource;
        setValidFiles(progressInfo);

        return [formData, config, uniqueId];
    };

    const updateProgress = (fileRef, progress) => {
        let index = validFiles.findIndex((file) => {
            return file["fileRef"] === fileRef;
        });
        if (index > -1) {
            let progressInfo = [...validFiles];
            progressInfo[index].progressPercentage = progress;
            setValidFiles(progressInfo);
        }
    };

    const removeUploadedFile = (fileRef) => {
        let index = validFiles.findIndex((file) => {
            return file["fileRef"] === fileRef;
        });
        if (index > -1) {
            validFiles.splice(index, 1);
            setIsUploading(validFiles.length > 0);
            setValidFiles([...validFiles]);
        }
    };

    const onFileSelect = async (acceptedFiles) => {
        try {
            if (isUploading && validFiles.length > 0) {
                toast.info(`Please wait until current upload is finished`);
            } else {
                if (validFiles.length + acceptedFiles.length <= maxFiles) {
                    const validFileSizes = validateFileSizes(acceptedFiles);
                    const uniqueFiles = validateDuplicateFiles(validFileSizes);
                    const preparedFiles = appendFileProgress(uniqueFiles);
                    setPreValidationFinished(false);
                    setValidFiles([...validFiles, ...preparedFiles]);
                    setIsUploading(false);
                } else {
                    toast.info(`Please select only ${maxFiles} file(s)`);
                }
            }
        } catch (error) {
            console.log(error);
            toast.info(`Oop's something went wrong. Please try again`);
        }
    };

    const onLoadError = () => {
        toast.error("The document you have selected is corrupted");
    };

    // API Calls
    const uploadFiles = async (file, index) => {
        const data = await prepareDataToUpload(file, index);
        RedactionService.upload(data[0], data[1])
            .then((response) => {
                removeUploadedFile(data[2]);
                Utils.handleSuccessRepsonse(response);
                onNewFileUploaded();
            })
            .catch((error) => {
                removeUploadedFile(data[2]);
                Utils.handleRedactErrorResponse({ error });
            });
    };

    //UI Elements
    const ToolTipContent = () => {
        return (
            <>
                <Box>
                    Allowed files are .png, .jpg, .jpeg, .pdf, .docx, .doc.
                </Box>
                <Box>
                    Allowed file size is
                    {` ${maxPDFFileSize}MB for PDF, ${maxImageFileSize}MB for Images `}
                    .
                </Box>
                <Box>
                    Allowed files count is
                    {` ${maxFiles}`}.
                </Box>
                <Box>Max page count per PDF is {` ${maxPageCount}`}.</Box>
            </>
        );
    };
    const ToolTipMessage = () => {
        return `Allowed files are .png, .jpg, .jpeg, .pdf, .docx, .doc. Allowed file size is ${maxPDFFileSize}MB for PDF, ${maxImageFileSize}MB for Images. Allowed files count is ${maxFiles}. Max page count per PDF is ${maxPageCount}`;
    };
    return (
        <>
            <Box py={1} display="flex" justifyContent="space-between">
                <Typography className="document-header">
                    Upload a document
                </Typography>

                <span className="tooltip-container">
                    <Button
                        disableRipple
                        disableFocusRipple
                        className="file-hint"
                        role="tooltip"
                        aria-labelledby="file-hint-tooltip"
                    >
                        View file types and restrictions
                    </Button>
                    <Box className="tooltip-content" id="file-hint-tooltip">
                        {ToolTipMessage()}
                    </Box>
                </span>
            </Box>
            <Dropzone
                accept={[".pdf", ".png", ".jpg", ".jpeg", ".docx", ".doc"]}
                ref={dropzoneRef}
                onDrop={onFileSelect}
                noClick={validFiles.length !== 0}
                onDropRejected={() =>
                    toast.error(
                        "The document file type you have selected is unsupported and cannot be uploaded."
                    )
                }
            >
                {({ getRootProps, getInputProps, open }) => (
                    <section className="drop-zone">
                        <div {...getRootProps()}>
                            <input {...getInputProps()} />
                            {validFiles.length === 0 && (
                                <Box className="drop-hint-area">
                                    <React.Fragment>
                                        <img
                                            src="/images/icons/icon-file-upload.png"
                                            alt=""
                                        />
                                        <Box className="title">
                                            Drag and drop documents here
                                        </Box>
                                        <Button className="button">
                                            Browse files
                                        </Button>
                                    </React.Fragment>
                                </Box>
                            )}
                            {validFiles.length !== 0 && (
                                <Box className="drop-preview">
                                    <Box className="file-thumbnails">
                                        {validFiles.map((file, index) => (
                                            <Box className="file-thumb">
                                                <img
                                                    src="/images/icons/icon_file2.png"
                                                    alt=""
                                                    className="file-logo"
                                                />

                                                <Typography className="file-name">
                                                    {Utils.truncateMiddle(
                                                        file.name
                                                    )}
                                                </Typography>
                                                {!isUploading && (
                                                    <img
                                                        src="/images/icons/icon_tick.png"
                                                        alt=""
                                                        className="icon-tick"
                                                    />
                                                )}
                                                {isUploading && (
                                                    <LinearProgress
                                                        className="progress"
                                                        variant="determinate"
                                                        value={
                                                            file.progressPercentage
                                                        }
                                                    />
                                                )}
                                                {file.progressPercentage <
                                                    100 && (
                                                    <IconButton
                                                        className="icon-remove disable-button-hover"
                                                        onClick={() => {
                                                            actionCancelUpload(
                                                                index
                                                            );
                                                        }}
                                                        disableFocusRipple
                                                        disableRipple
                                                        aria-label={`Remove ${file.name}`}
                                                    >
                                                        <img
                                                            src={
                                                                isUploading
                                                                    ? "/images/icons/icon_close.png"
                                                                    : "/images/icons/icon_remove.png"
                                                            }
                                                            alt=""
                                                            className="icon-tick"
                                                        />
                                                    </IconButton>
                                                )}
                                            </Box>
                                        ))}
                                    </Box>

                                    <Box className="upload-footer">
                                        <Button
                                            className="btn-browse"
                                            onClick={actionSelectFile}
                                            disabled={isUploading}
                                        >
                                            Browse files
                                        </Button>
                                        <Button
                                            className="btn-green"
                                            onClick={() => {
                                                setIsUploading(true);
                                                actionUpload();
                                            }}
                                            disabled={isUploading}
                                        >
                                            {`Upload(${validFiles.length})`}
                                        </Button>
                                    </Box>
                                    {/* )} */}
                                </Box>
                            )}
                        </div>
                    </section>
                )}
            </Dropzone>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        redactDocumentState: state.redactDocumentState,
    };
};

const mapActionToProps = (dispatch) => {
    return {
        setLoginData: (data) => dispatch(setLoginData(data)),
    };
};

export default connect(mapStateToProps, mapActionToProps)(DocumentUpload);
