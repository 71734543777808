import { RedactionService } from "./redaction";
import { SubscriptionService } from "./subscriptions";
import { ReportService } from "./report";
import { AuthService } from "./authentication";
import { AccessManagementService } from "./AccessManagementService";

export {
    RedactionService,
    SubscriptionService,
    ReportService,
    AuthService,
    AccessManagementService,
};

export const DefaultPagination = (limit) => {
    return {
        offset: 1,
        lastOffset: 0,
        limit,
        totalCount: 0,
    };
};
