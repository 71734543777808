import ApiManager from "../shared/managers/api-manager";
import { RestEnds } from "../shared/constants";
import moment from "moment";
import "moment-timezone";
const timeZone = moment.tz.guess();

const getReports = async (fromDate, toDate, groupIds) => {
    return ApiManager.sendGet(
        `${RestEnds.REPORT}?fromDate=${fromDate}&toDate=${toDate}&timezone=${timeZone}&groupIds=${groupIds}`,
        true
    );
};

const getDetailedHistory = async (
    limit,
    offset,
    fromDate,
    toDate,
    groupIds
) => {
    const url = `${RestEnds.REPORT_DETAILED_HISTORY}?limit=${limit}&offset=${offset}&timezone=${timeZone}&fromDate=${fromDate}&toDate=${toDate}&groupIds=${groupIds}`;
    return ApiManager.sendGet(url, true);
};

export const ReportService = {
    getReports,
    getDetailedHistory,
};
