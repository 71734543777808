import update from "immutability-helper";

const PolicyState = {
    autoRedactionEnabled: false,
    customRedactionEnabled: false,
    percentageRedactionEnabled: false,
    drawRedactionEnabled: false,
    templateRedactionEnabled: false,
    templateList: [],
    templates: [],
    preselectedTemplates: [],
};

const SET_POLICY_CONFIG = "SET_POLICY_CONFIG";

export const setPolicyConfig = (payload) => ({
    type: SET_POLICY_CONFIG,
    payload,
});

const PolicyReducer = (state = PolicyState, action) => {
    switch (action.type) {
        case SET_POLICY_CONFIG:
            return update(state, {
                $merge: {
                    ...action.payload,
                },
            });
        default:
            return state;
    }
};

export default PolicyReducer;
