import update from "immutability-helper";
import { DefaultPagination } from "../services";

const sharedState = {
    historyTable: {
        offset: 1,
        lastOffset: 0,
        limit: 10,
        totalCount: 0,
    },
};

const SET_HISTORY_TABLE_STATE = "SET_HISTORY_TABLE_STATE";

export const setHistoryTableState = (payload) => ({
    type: SET_HISTORY_TABLE_STATE,
    payload,
});

const SharedReducer = (state = sharedState, action) => {
    switch (action.type) {
        case SET_HISTORY_TABLE_STATE:
            return update(state, {
                $merge: {
                    historyTable: action.payload,
                },
            });
        default:
            return state;
    }
};

export default SharedReducer;
