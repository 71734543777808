import DocumentPreview from "./components/document-preview";

const RedactPreview = () => {
    return (
        <>
            <DocumentPreview />
        </>
    );
};

export default RedactPreview;
