import React, { useState } from "react";
import {
    MenuItem,
    TextField,
    InputLabel,
    Grid,
    Box,
    Button,
    CircularProgress,
    Chip,
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import { useField } from "formik";
import DateFnsUtils from "@date-io/date-fns";

export const AppTextField = ({ label, ...props }) => {
    const [field, meta] = useField(props);
    return (
        <>
            {label && (
                <InputLabel className="f14 lato-regular text-greyed">
                    {label}
                </InputLabel>
            )}
            <TextField
                variant={props.variant ? props.variant : "outlined"}
                margin="normal"
                error={meta.touched && meta.error}
                helperText={meta.touched && meta.error}
                fullWidth
                size={props.size ? props.size : "small"}
                placeholder={props.placeholder ? props.placeholder : ""}
                name={field.name}
                type={props.type ? props.type : "text"}
                className={
                    props.className
                        ? props.className + " f14 lato-regular app-inputbox"
                        : "f14 lato-regular app-inputbox"
                }
                value={meta.value}
                onChange={field.onChange}
                onBlur={field.onBlur}
                InputProps={{
                    readOnly: props.readOnly,
                    startAdornment: props.startAdornment,
                }}
            />
        </>
    );
};

export const AppPassword = ({ label, ...props }) => {
    const [showPassword, setShowPassword] = useState(false);
    const [field, meta] = useField(props);
    return (
        <>
            <InputLabel className="f14 lato-regular text-greyed">
                {label}
            </InputLabel>
            <TextField
                variant={props.variant ? props.variant : "outlined"}
                margin="normal"
                error={meta.touched && meta.error}
                helperText={meta.touched && meta.error}
                fullWidth
                size={props.size ? props.size : "small"}
                placeholder={props.placeholder ? props.placeholder : ""}
                name={field.name}
                type={showPassword ? "text" : "password"}
                className={
                    props.className
                        ? props.className + " f14 lato-regular app-inputbox"
                        : "f14 lato-regular app-inputbox"
                }
                value={meta.value}
                onChange={field.onChange}
                onBlur={field.onBlur}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => {
                                    setShowPassword(!showPassword);
                                }}
                                edge="end"
                            >
                                {showPassword ? (
                                    <Visibility />
                                ) : (
                                    <VisibilityOff />
                                )}
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
            />
        </>
    );
};

export const AppSelectBox = ({
    onChange,
    label,
    items,
    titleKey,
    valueKey,
    ...props
}) => {
    const [field, meta] = useField(props);
    return (
        <>
            <InputLabel className="f14 lato-regular primary-dark">
                {label}
            </InputLabel>
            <TextField
                style={{
                    marginTop: "16px",
                    marginBottom: "8px",
                }}
                error={meta.touched && meta.error}
                select
                fullWidth
                margin="normal"
                variant="outlined"
                value={meta.value}
                name={field.name}
                onBlur={(e) => {
                    field.onBlur(e);
                    if (onChange) onChange(e);
                }}
                onChange={(e) => {
                    field.onChange(e);
                    if (onChange) onChange(e);
                }}
                size={props.size}
                className="f14 lato-regular app-inputbox"
                inputProps={{ readOnly: props.readOnly }}
            >
                {items.map((item, i) => (
                    <MenuItem key={field.name + i} value={item[valueKey]}>
                        {item[titleKey]}
                    </MenuItem>
                ))}
            </TextField>
        </>
    );
};

export const AppDatePicker = (props) => {
    const { label, minDate, maxDate, name, value, onChange } = props;
    return (
        <Box className="justify-content">
            <InputLabel className="f14 myriadpro-regular text-center ">
                {label}
            </InputLabel>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Box mx={2}>
                    <DatePicker
                        inputVariant="outlined"
                        clearable
                        size="small"
                        autoOk
                        value={value}
                        name={name}
                        id={name}
                        style={{ width: 150 }}
                        margin="none"
                        onChange={onChange}
                        minDate={minDate}
                        maxDate={maxDate}
                        animateYearScrolling
                        // invalidDateMessage={meta.touched && meta.error}
                        InputAdornmentProps={{ position: "end" }}
                        views={["year", "month", "date"]}
                        placeholder={"MM/DD/YYYY"}
                        format={"MM/dd/yyyy"}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <CalendarTodayIcon color="primary" />
                                </InputAdornment>
                            ),
                        }}
                    />
                </Box>
            </MuiPickersUtilsProvider>
        </Box>
    );
};

export const AppButton = ({
    size,
    buttonName,
    variant,
    isLoading,
    type,
    disabled,
    action,
    endIcon,
    startIcon,
    className,
    fullWidth,
    loadingText,
}) => {
    return (
        <Button
            color="primary"
            className={className}
            disabled={disabled}
            fullWidth={fullWidth}
            size={size ? size : "large"}
            type={type}
            variant={variant ? variant : "contained"}
            onClick={action}
            startIcon={startIcon ? startIcon : <></>}
            endIcon={endIcon ? endIcon : <></>}
        >
            {isLoading === true ? (
                <>
                    <CircularProgress className="btn-loader" />
                    <span style={{ marginLeft: "10px" }}>
                        {loadingText ? loadingText : "Processing"}
                    </span>
                </>
            ) : (
                <span>{buttonName}</span>
            )}
        </Button>
    );
};

export const AppPlainButton = ({
    size,
    buttonName,
    variant,
    isLoading,
    type,
    disabled,
    action,
    endIcon,
    loadingText,
}) => {
    return (
        <Button
            color="primary"
            disabled={disabled}
            size={size ? size : "large"}
            type={type}
            onClick={action}
        >
            {isLoading === true ? (
                <>
                    <CircularProgress className="btn-loader" />
                    <span style={{ marginLeft: "10px" }}>
                        {loadingText ? loadingText : "Processing"}
                    </span>
                </>
            ) : (
                <span>{buttonName}</span>
            )}
        </Button>
    );
};

export const AppSelectCountry = ({ onChange, label, items, ...props }) => {
    const [field, meta] = useField(props);
    return (
        <>
            <InputLabel className="f14 lato-regular primary-dark">
                {label}
            </InputLabel>
            <TextField
                error={meta.touched && meta.error}
                select
                fullWidth
                margin="normal"
                variant="outlined"
                value={meta.value}
                name={field.name}
                onBlur={(e) => {
                    field.onBlur(e);
                    if (onChange) onChange(e);
                }}
                onChange={(e) => {
                    field.onChange(e);
                    if (onChange) onChange(e);
                }}
                size={props.size}
                className="f14 lato-regular app-inputbox"
                inputProps={{ readOnly: props.readOnly }}
            >
                {items.map((item, i) => (
                    <MenuItem key={field.name + i} value={item}>
                        {item}
                    </MenuItem>
                ))}
            </TextField>
        </>
    );
};

export const ConfirmDialog = (props) => {
    let {
        title,
        subTitle,
        showConfirm,
        cancelTitle,
        successTitle,
        isPositiveConfirm,
    } = props;

    //Actions
    const actionPositive = () => {
        props.onSuccess();
    };
    const actionNegative = () => {
        props.onCancel();
    };

    //UI Elements
    return (
        <>
            <Dialog
                // fullWidth={true}
                className="app-confirm-dialog"
                maxWidth={"md"}
                open={showConfirm}
            >
                <DialogContent>
                    <Box className="title">{title}</Box>
                    <Box
                        className="sub-title"
                        dangerouslySetInnerHTML={{
                            __html: subTitle,
                        }}
                    ></Box>
                    <Box marginTop="20px" marginBottom="20px">
                        <Grid container spacing={2}>
                            <Grid item sm={6}>
                                <Button
                                    type="submit"
                                    fullWidth
                                    className="btn-grey"
                                    onClick={actionNegative}
                                >
                                    {cancelTitle}
                                </Button>
                            </Grid>
                            <Grid item sm={6}>
                                <Button
                                    type="submit"
                                    fullWidth
                                    className={
                                        isPositiveConfirm
                                            ? "btn-primary"
                                            : "btn-danger"
                                    }
                                    onClick={actionPositive}
                                >
                                    {successTitle}
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </DialogContent>
            </Dialog>
        </>
    );
};

export const YesNoChip = (props) => {
    if (props["flag"] === "Yes") {
        return <Chip label="Yes" color="primary" />;
    } else {
        return <Chip label="No" color="primary" variant="outlined" />;
    }
};
