import update from "immutability-helper";

const redactState = {
    canDraw: false,
    selectedDocuments: [],
    pageNumber: 1,
    fileIndex: 0,
    files: [],
    editorTool: {
        imgWidth: 0,
        imgHeight: 0,
    },
    selectedRequestDocuments: [],
    webSocketUrl: "LOAD_FROM_SERVER",
};

const SET_SELECTED_DOUCMENT = "SET_SELECTED_DOUCMENT";
const SET_PAGE_NUMBER = "SET_PAGE_NUMBER";
const SET_FILE_INDEX = "SET_FILE_INDEX";
const SET_DRAWABLES = "SET_DRAWABLES";
const SET_EDITOR_STYLE = "SET_EDITOR_STYLE";
const SET_CAN_DRAW = "SET_CAN_DRAW";
const SET_SELECTED_REQUEST_DOCUMENTS = "SET_SELECTED_REQUEST_DOCUMENTS";
const SET_WEBSOCKET_URL = "SET_WEBSOCKET_URL";

export const setSelectedRequestDocuments = (payload) => ({
    type: SET_SELECTED_REQUEST_DOCUMENTS,
    payload,
});

export const setSelectedDocument = (payload) => ({
    type: SET_SELECTED_DOUCMENT,
    payload,
});

export const setPageNumber = (payload) => ({
    type: SET_PAGE_NUMBER,
    payload,
});

export const setFileIndex = (payload) => ({
    type: SET_FILE_INDEX,
    payload,
});

export const setDrawables = (payload) => ({
    type: SET_DRAWABLES,
    payload,
});

export const setEditorToolStyle = (payload) => ({
    type: SET_EDITOR_STYLE,
    payload,
});

export const setCanDraw = (payload) => ({
    type: SET_CAN_DRAW,
    payload,
});

export const setWebSocketUrl = (payload) => ({
    type: SET_WEBSOCKET_URL,
    payload,
});

const RedactStateReducer = (state = redactState, action) => {
    switch (action.type) {
        case SET_SELECTED_DOUCMENT:
            return update(state, {
                $merge: {
                    ...state,
                    selectedDocuments: action.payload,
                },
            });
        case SET_PAGE_NUMBER:
            return update(state, {
                $merge: {
                    pageNumber: action.payload,
                },
            });
        case SET_FILE_INDEX:
            return update(state, {
                $merge: {
                    fileIndex: action.payload,
                },
            });
        case SET_DRAWABLES:
            return update(state, {
                $merge: {
                    files: action.payload,
                },
            });
        case SET_EDITOR_STYLE:
            return update(state, {
                $merge: {
                    editorTool: action.payload,
                },
            });
        case SET_CAN_DRAW:
            return update(state, {
                $merge: {
                    canDraw: action.payload,
                },
            });
        case SET_SELECTED_REQUEST_DOCUMENTS:
            return update(state, {
                $merge: {
                    selectedRequestDocuments: action.payload,
                },
            });
        case SET_WEBSOCKET_URL:
            return update(state, {
                $merge: {
                    webSocketUrl: action.payload,
                },
            });
        default:
            return state;
    }
};

export default RedactStateReducer;
